.joinAsGuestWrap,
.company-wrap {
  @media screen and (max-width: 540px) {
    padding: 0 !important;
  }

  &.company-wrap {
    padding-bottom: 100px;
    .jag_logo {
      padding: 30px 0 0;
      img {
        width: 100%;
        max-width: 160px;
      }
    }
  }

  &.joinAsGuestWrap {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 100vh;
    overflow: auto;
    padding: 175px 20px 20px;
    background-size: cover;
    background-position: center center;
    color: $color-white;
    text-align: center;
    background-image: url(../../assets/images/Docto_Home_HeaderBanner.jpg);
  }

  .jag_brand {
    width: 105px;
    height: 45px;
    margin: auto auto 0;
    background-size: contain;
    background-position: center bottom;
    color: $color-white;
    text-align: center;
    background-image: url(../../assets/images/call-header-brand.png);
    background-repeat: no-repeat;

    @media screen and (max-width: 540px) {
      display: none;
    }
  }

  .jag_info_wrap {
    margin: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 320px;
    height: auto;
    padding: 50px 28px 40px;
    background: #fff;
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    @media screen and (max-width: 540px) {
      margin: $top-bar-height 0 0;
      padding: 20px 20px 40px;
      border-radius: 0;
      max-width: none;
      min-height: calc(100vh - 60px);
    }
    .error-alert {
      margin: -10px 0 10px;
      background: rgba(194, 83, 83, 0.3);
      border-radius: 4px;
      margin-bottom: 15px;
      padding: 10px;
      text-align: left;
      font-size: 0.75em;
      color: #c25353;
      line-height: 1.2;

      p {
        margin-bottom: 4px;
      }

      ul {
        list-style: disc;
        margin: 0 0 4px 14px;

        li {
        }
      }
    }
  }

  .jbs-message {
    width: 100%;
    border-radius: 6px;
    margin: 0 auto 10px;

    .message-alert-box {
      margin: 0;
    }
  }

  .jag_avatar {
    position: absolute;
    width: 80px;
    height: 80px;
    top: 0;
    left: 50%;
    margin-top: -40px;
    margin-left: -40px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-image: url(../../assets/images/patient_placeholder.jpg);
    background-color: #f5f5f5;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

    @media screen and (max-width: 540px) {
      position: initial;
      margin: 0 auto 15px;
    }
  }

  .jag_prev {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    a {
      cursor: pointer;
      font-size: 14px;
      color: $color-doc-brand-sec;
      display: flex;

      i {
        font-size: 12px;
      }
    }
  }

  .jag_title {
    font-size: 30px;
    text-align: center;
    color: $color-font-grey;
    font-weight: 400;
    margin-bottom: 0;
  }

  .jag_sub_title {
    margin-top: 10px;
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: $color-font-grey;
    font-weight: 400;
  }
  .jag_sub_title_capitalize {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    color: $color-font-grey;
    font-weight: 400;
    .join_now {
      text-transform: uppercase;
      font-weight: bold;
      font: italic;
    }
  }

  .jag_body_wrap {
    margin-top: 20px;

    .jag-solid-btn,
    .jag-lined-box,
    .PhoneInputInput {
      display: block;
      width: 100%;
      max-width: 320px;
      height: 40px;
      line-height: 40px;
      padding: 0 10px;
      font-size: 0.9em;
      margin-bottom: 8px;
      font-weight: 400;

      @media screen and (max-width: 540px) {
        max-width: none;
      }
    }

    .PhoneInputInput {
      border-radius: 4px;
    }

    .PhoneInputCountry {
      margin-bottom: 10px;
    }

    .jag-solid-btn {
      cursor: pointer;
      background: $color-doc-brand-sec;
      margin-bottom: 8px;
      margin-top: 30px;
      color: $color-white;
      font-weight: bold;

      &:hover {
        background: darken($color-doc-brand-sec, 5%);
      }

      &.disabled {
        background: $color-grey !important;
        color: $color-white;
      }

      &.jag_fixed-bottom {
        @media screen and (max-width: 540px) {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 0;
          margin: 0;
        }
      }
    }

    .jag-lined-box {
      background: $color-white;
      border: 1px solid $color-dark-grey;
      color: $color-dark-grey;
    }
  }

  .PhoneInputCountryIcon,
  .PhoneInputCountrySelectArrow {
    color: #888888;
  }
}

.jag-call-setup {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
  padding: 10px 30px;
}

.jag_body_settings,
.jag_body_settings_inner {
  margin-top: 20px;
  margin-bottom: 10px;
  .camerahide {
    display: none;
    width: 1px;
    height: 1px;
  }

  .jbs-camera {
    width: 260px;
    height: 168px;
    border-radius: 6px;
    background: $color-dark-grey;
    margin: 0 auto 20px;

    div {
      border-radius: 6px;
    }
  }

  .set-row {
    display: flex;
    align-items: center;
    margin: 12px 0;
    padding-bottom: 12px;
    border-bottom: 1px solid $color-dark-grey;
    flex-wrap: wrap;

    &:last-child {
      border: none;
    }

    i {
      color: $color-grey;
      width: 30px;
    }

    .icon-Camera_DOCTO {
      font-size: 12px;
    }

    .icon-Mic_DOCTO {
      font-size: 18px;
    }

    .icon-Speakers_DOCTO {
      font-size: 15px;
    }

    .icon-Wifi_DOCTO {
      font-size: 13px;
    }

    span {
      color: $color-dark-grey;
      font-size: 0.8em;
      font-weight: 400;
    }

    .success {
      color: $color-mz-green;
      font-size: 0.8em;
      font-weight: 400;
    }

    .warning {
      color: $color-mz-yellow;
      font-size: 0.8em;
      font-weight: 400;
    }

    .error {
      color: $color-mz-red;
      font-size: 0.8em;
      font-weight: 400;
    }

    .actions {
      display: flex;
      margin-left: auto;

      i {
        font-size: 14px;
      }

      a.block-btn {
        font-size: 0.675em;
        padding: 4px;
        margin-right: 5px;
        font-weight: 400;
      }

      a.block-btn.active {
        background-color: $color-doc-brand-sec;
        color: $color-white;
      }

      a.block-btn.disabled {
        background-color: $color-grey;
        color: $color-white;
      }

      a.switcher {
        position: relative;
        width: 32px;
        height: 18px;
        border-radius: 9px;
        background-color: $color-grey;

        &:before {
          content: "";
          position: absolute;
          top: 1px;
          left: 1px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: $color-white;
        }

        &.active {
          background-color: $color-doc-brand-sec;

          &:before {
            right: 1px;
            left: initial;
          }
        }
      }
    }

    .counter {
      height: 5px;
      width: 230px;
      margin-top: 5px;
      margin-left: 30px;
      margin-right: 5px;
      background: $color-grey;

      .counter-inner {
        height: 5px;
        background: $color-doc-brand-sec;
        -webkit-transition: width 0.1s;
        transition: width 0.1s;
      }
    }
  }
}

.jag_body_settings_inner {
  margin: 5px 0 0;

  .set-row {
    i,
    span {
      color: $color-white;
    }
  }
}

.jag_footer_wrap {
  margin-top: 5px;
  margin-bottom: 50px;
  text-align: center;
  font-size: 0.9em;
  font-weight: 400;

  span {
    color: $color-font-grey;
  }

  a {
    color: $color-doc-brand-sec;
    font-weight: 400;
  }
}

.jag_footer_action {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  border-radius: 0;
  margin: 0;

  @media screen and (min-width: 540px) {
    display: none;
  }

  a {
    height: 40px;
    background-color: $color-doc-brand-sec;
    line-height: 40px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: block;
    width: 100%;
    color: $color-white;
    font-weight: 500;
    font-size: 0.9em;

    &:hover {
      background: darken($color-doc-brand-sec, 5%);
    }

    &.disabled {
      background-color: $color-mid-grey;
      color: #fff;
      cursor: not-allowed;
    }

    @media screen and (max-width: 540px) {
      border-radius: 0;
    }
  }
}

.jag_join_mid {
  margin-top: -10px;

  a {
    height: 34px;
    background-color: $color-doc-brand-sec;
    line-height: 34px;
    border-radius: 4px;
    display: block;
    width: 100%;
    color: #fff;
    font-weight: 400;
    font-size: 0.9em;
    padding: 0 10px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    &:hover {
      background-color: darken($color-doc-brand-sec, 5%);
      text-decoration: none;
    }

    &.disabled {
      background-color: $color-mid-grey;
      color: #fff;
      cursor: not-allowed;
    }
  }

  @media screen and (max-width: 540px) {
    display: none;
  }
}

.jagw_form {
  .jag_info_wrap {
    padding-top: 20px;
  }

  .react-phone-number-input__row {
    margin-bottom: 10px;
  }

  .jag-lined-box,
  .form-control,
  .react-phone-number-input__phone--native {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    line-height: 40px;
    padding: 0 10px;
    font-size: 0.875em;
    border-color: #cccccc !important;
    color: #878787;
    margin: 0 0 8px 0;
  }

  .jag-solid-btn {
    border-radius: 4px;
  }

  .jag_footer_wrap {
    a {
      margin: 0 5px;
    }
  }

  .rdtPicker {
    color: #444;
  }
}
