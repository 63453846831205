///*------------------------------------*\
//    DOCTO UI - DPHILLIPS
//\*------------------------------------*/

// Mixins
@import "ui-fonts/_sf.scss";
@import "ui-fonts/_digital_seven.scss";
@import "https://fonts.googleapis.com/css?family=Open+Sans:400,300,400italic,300italic,700,700italic";
@import "ui-util/bourbon/_bourbon.scss";

// BASE:
@import "ui-base/_reset.scss";
@import "ui-base/_var.scss";
@import "ui-base/_colors.scss";
@import "ui-base/_fonts.scss";
@import "ui-base/_icons.scss";
@import "ui-base/_main.scss";
@import "ui-base/_utilities.scss";

//LANDING PAGE
@import "ui-landing/_landing-page.scss";
@import "ui-landing/_landing-page-grid.scss";
@import "ui-landing/_landing-page-add-on.scss";
@import "ui-landing/_header.scss";
@import "ui-landing/_landing-page-login.scss";
@import "ui-landing/_landing-modal.scss";
@import "ui-landing/_landing-page-login.scss";
@import "ui-landing/_landing-form.scss";
@import "ui-landing/_landing-business-page";
@import "ui-landing/_landing-pricing.scss";
@import "ui-landing/_landing-register-header.scss";
@import "ui-landing/_specialist-modal.scss";
@import "ui-landing/_landing-simple.scss";
@import "ui-landing/_slaask.scss";

// PLUGINS
@import "ui-plugins/linear-icons/linear-icons.scss";
@import "ui-plugins/_animate.scss";
@import "ui-plugins/_react-datetime.scss";
@import "ui-plugins/_react-select.scss";
@import "ui-plugins/_toastr.min.scss";
@import "ui-plugins/_calendar.scss";
@import "~react-phone-number-input/style.css";

// MODULES:
@import "ui-modules/_loader.scss";
@import "ui-modules/_load-animation.scss";
@import "ui-modules/_tootips-alerts.scss";
@import "ui-modules/_forms.scss";
@import "ui-modules/_buttons.scss";
@import "ui-modules/_top-nav.scss";
@import "ui-modules/_chat-panel.scss";
@import "ui-modules/_register.scss";
@import "ui-modules/_profile.scss";
@import "ui-modules/_meeting.scss";
@import "ui-modules/_doc-list.scss";
@import "ui-modules/_appointments.scss";
@import "ui-modules/react-star-rating.scss";
@import "ui-modules/error-page.scss";
@import "ui-modules/_switch.scss";
@import "ui-modules/_billing.scss";
@import "ui-modules/_dropdown.scss";
@import "ui-modules/_bot.scss";
@import "ui-modules/_pricing.scss";
@import "ui-modules/_call-page.scss";
@import "ui-modules/_call-rate.scss";
@import "ui-modules/_meeting-sidebar.scss";
@import "ui-modules/_patient-invite.scss";
@import "ui-modules/_jotform.scss";
@import "ui-modules/_dr-profile-page.scss";
@import "ui-modules/_payments-modal.scss";
@import "ui-modules/_join-as-guest.scss";
@import "ui-modules/_tables.scss";
@import "ui-modules/_admin.scss";
@import "ui-modules/_message_alerts.scss";
@import "ui-modules/_specialty-page.scss";
@import "ui-modules/_referral-drop.scss";
@import "ui-modules/circular-loader";

// RESPONSIVE:
@import "ui-base/_responsive.scss";
@import "ui-landing/_landing-responsive.scss";
@import "ui-modules/_profile_responsive.scss";

//EXTERNAL CSS
@import "css/emoji-mart";
@import "css/telephone-number-input";
@import "css/react-table-6";

//EXTRAS
@import "ui-modules/_header-btn.scss";

// THEMES:
@import "ui-theme/_ahi.scss";
@import "ui-theme/_gold-coast-private-hospital.scss";
@import "ui-theme/_r2s.scss";
@import "ui-theme/_assistravel.scss";

//VERSION:
@import "ui-landing/_version-number.scss";
