//Call Rate
.call-rate-body {
  margin-top: 15px;

  .call-rate {
    &_wrap {
    }

    &_doctor {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;

      .doc-image {
        position: relative;
        flex: 0 0 85px;
        margin: 0 10px 0;
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background-position: center center;
        background-size: cover;
        background-image: url(../../assets/images/patient_placeholder.jpg);

        .initials {
          text-transform: uppercase;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color-white;
          font-size: 35px;
          border-radius: inherit;
          background-color: $color-doc-brand-sec;
        }
      }

      .doc-info {
        margin: 0 10px;
        flex: 0 1 auto;
        display: flex;
        flex-direction: column;

        span {
          display: block;
          line-height: 1.2;
        }

        .degrees {
          margin-top: 5px;
          font-size: 0.75em;
          font-weight: 500;
        }
      }
    }

    &_vote {
      padding: 20px;
      display: flex;
      justify-content: center;

      .vote-icon-wrap {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-self: center;
        text-align: center;
        margin: 0 15px;
        border-radius: 40px;
        background-color: $color-white;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        border: 1px solid $color-white;

        &:hover,
        &.active {
          background-color: #f9f9f9;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        }

        &.good:hover,
        &.good.active {
          border: 1px solid #27c690;
        }

        &.bad:hover,
        &.bad.active {
          border: 1px solid #c52442;
        }

        img {
          width: 35px;
          margin: 0 auto;
        }
      }
    }

    &_comment {
      .commet-head {
        display: flex;
        justify-content: space-between;

        span {
          display: block;
        }

        .saving-message {
          padding: 2px 5px;
          margin: 0;
          font-size: 10px;
          display: flex;
          align-items: center;

          i {
            margin-left: 3px;
            font-size: 10px;
          }
        }
      }

      textarea {
        position: relative;
        background: #fff;
        border-radius: 4px;
        border-left: 3px solid #b1afaf;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
        margin: 10px 0;
        display: flex;
        border: 1px solid $color-blueish-grey;
        border-left: 3px solid $color-danger;
        font-size: 0.875em;

        &:focus {
          outline: none;
        }
      }
    }

    &_stars {
      margin-top: 8px;
      text-align: center;

      i {
        display: inline-block;
        vertical-align: middle;
        font-size: 26px;
        color: $color-darker-grey;
        margin: 4px;

        &:after {
          content: "\ece3";
        }

        &.active,
        &:hover {
          cursor: pointer;
          color: $color-warning;

          &:after {
            content: "\ece5";
          }
        }
      }
    }

    &_date,
    &_price,
    &_note {
      margin-bottom: 30px;

      span {
        display: block;
      }
    }

    &_comment,
    &_date {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
