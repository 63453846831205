.meeting-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  overflow: hidden;

  &.with-scroll {
    overflow: auto;
  }
}

.meeting-holder {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-main-bg;

  .meeting-message-banner {
    z-index: 2;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    padding: 12px;
    background: rgba(0, 0, 0, 0.75);
    color: $color-white;
    font-weight: 400;
    font-size: 12px;

    &.info {
      background: $color-info;
    }

    &.success {
      background: $color-success;
    }

    &.danger {
      background: $color-danger;
    }

    &.warning {
      background: $color-warning;
    }
  }

  .vid-swap {
    display: none;
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0;
    width: auto;
    height: 40px;
    background: $color-light-grey;
    border-top: 2px solid $color-border-grey;
    border-left: 2px solid $color-border-grey;

    &:hover {
      text-decoration: none;
    }
    span,
    i {
      float: left;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 1.25em;
    }

    span {
      padding: 0 30px;
      border-right: 2px solid $color-border-grey;
    }

    i {
      width: 40px;
    }
  }
}

.video-box-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;

  .vid-box {
    margin: 0 $main-margin 0 0;
    width: 340px;
    background: $color-dark-grey;
    @extend .box-shad-main;
  }

  .vid-box,
  .vid-box-float {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: bottom;
    height: auto;
    &.vid-half,
    &.vid-full {
      position: fixed;
      z-index: 0;
      width: auto;
      top: $top-bar-height;
      left: 0;
      right: 310px;
      bottom: 0;
      margin: 0;
      background-color: $color-main-bg;
      @include border-top-radius(0);
      @include border-bottom-radius(0);
      box-shadow: none;
    }
    &.vid-half {
      width: 50%;
      &.right {
        left: 50%;
      }
      &.left {
        right: 50%;
      }
    }
    &.expand {
      right: 0;
    }
  }
  .enter-meeting {
    position: fixed;
    width: 450px;
    height: auto;
    top: 185px;
    left: 50%;
    margin-left: -225px;
    @include border-top-radius(3px);
    @include border-bottom-radius(3px);
    .vid-logo {
      position: absolute;
      height: 100px;
      width: 100%;
      top: -120px;
      text-align: center;
      color: $color-dark-grey;
      img {
        height: 100%;
      }
    }
    .vid-message {
      position: absolute;
      width: 100%;
      bottom: 100%;
      margin-bottom: 10px;
      text-align: center;
      color: $color-dark-grey;
      .timer {
        font-weight: 700;
        padding-top: 5px;
      }
    }
    .vid-header {
      display: none;
    }
    .vid-body {
      position: relative;
      height: 335px;
      @include border-top-radius(3px);
      -webkit-mask-image: -webkit-radial-gradient(
        circle,
        white 100%,
        black 100%
      );
      .bg-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        i {
          position: absolute;
          bottom: -20px;
          left: 0;
          width: 100%;
          font-size: 250px;
          color: $color-icon-light;
        }
      }
    }
    .vid-controls {
      top: 0;
    }
    .vid-footer {
      display: table;
      width: 100%;
      height: auto;
      min-height: 6px;
      background: $color-doc-brand;
      @include border-bottom-radius(3px);
      .footer-inner {
        position: relative;
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        input {
          box-sizing: border-box;
          width: 100%;
          padding: 0 110px 0 10px;
          border: none;
          height: 35px;
          cursor: text;
          @include border-bottom-radius(3px);
        }
        button {
          position: absolute;
          top: 0;
          right: 0;
          @include border-right-radius(3px);
          @include border-top-radius(0);
        }
      }
      .footer-help {
        position: absolute;
        bottom: 0;
        left: 0;
        p.enter {
          width: 100%;
          text-align: left;
          padding: 0px 26px;
          font-size: 1em;
          margin-bottom: 2px;
          color: $color-mid-grey;
        }
      }
      input {
        @extend .clean-input;
        display: inline-block;
        width: 330px;
        height: 33px;
        padding: 0 10px;
        background: $color-white;
        border: 1px solid $color-border-grey;
        font-size: 1em;
        &:focus {
          border-color: $color-doc-brand;
        }
      }
      button {
        @extend .clean-input;
        width: 100px;
        height: 35px;
        margin-left: -7px;
        display: inline-block;
        background: $color-doc-brand;
        color: $color-white;
        font-weight: 300;
        vertical-align: middle;
        &:hover {
          background-color: darken($color-doc-brand, 7.5%);
        }
        > * {
          line-height: 35px;
          vertical-align: middle;
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
  .enter-screen {
    height: auto;
    background: none;
    box-shadow: none;
    .vid-controls {
      display: none;
    }
    .vid-body {
      height: auto;
      border: none;
      background: none;
      text-align: center;
      .bg-icon {
        display: none;
      }
      img {
        width: 100%;
        max-width: 260px;
      }
    }
    .vid-footer {
      margin-top: 50px;
    }
  }
}

.meeting-holder.caregiver {
  .video-box-panel {
    .vid-box.enter-meeting {
      min-height: 440px;
      height: auto;
      .vid-footer {
        .footer-inner {
          padding: 20px 0;
          display: block;
          &:last-child {
            border-top: 1px solid rgba($color-white, 0.2);
          }
        }
      }
    }
  }
}

.vid-box-float,
.vid-box {
  .vid-header {
    height: 40px;
    width: 100%;
    text-align: right;
    background-color: $color-doc-brand;
    .device-name {
      font-size: 0.8em;
    }
    button {
      position: relative;
      @extend .clean-input;
      width: 40px;
      height: 40px;
      display: inline-block;
      margin: 0;
      background: rgba(0, 0, 0, 0.25);
      &:hover {
        background: rgba(0, 0, 0, 0.45);
      }
      &.icon-minimize {
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -2px 0 0 -9px;
          width: 18px;
          height: 3px;
          background: #fff;
          @include border-left-radius(2px);
          @include border-right-radius(2px);
        }
      }
    }
    span {
      float: left;
      color: $color-white;
      margin-left: 10px;
      line-height: 40px;
    }
  }
  .vid-body {
    position: relative;
    height: 260px;
    overflow: hidden;
    background-color: rgba($color-white, 0.1);
    border-bottom: none;
  }
  .vid-footer {
    display: none;
    a,
    p {
      line-height: 1.4;
      font-size: 0.95em;
      padding: 5px;
    }
    p {
      color: #fff;
    }
  }
  .vid-controls {
    position: absolute;
    top: 40px;
    right: 0;
    text-align: right;
    z-index: 10;
    margin: 10px;
    button {
      cursor: pointer;
      float: left;
      width: auto;
      min-width: 42px;
      padding: 0;
      height: 42px;
      background: rgba(0, 0, 0, 0.2);
      line-height: 42px;
      text-align: center;
      font-size: 22px;
      color: $color-white;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
      &:first-child {
        border-left: none;
        @include border-right-radius(0);
        @include border-left-radius(2px);
      }
      &:last-child {
        @include border-left-radius(0);
        @include border-right-radius(2px);
      }
      &:hover {
        background: rgba(0, 0, 0, 0.25);
      }
    }
    i {
      width: 30px;
      height: 30px;
      display: inline-block;
      vertical-align: middle;
      margin: 10px 0 0 -5px;
      font-size: 30px;
      line-height: 30px;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
      &.connection-low {
        color: $color-connect-orange;
      }
      &.connection-lost {
        color: $color-connect-red;
      }
    }
  }
  &.vid-half,
  &.vid-full {
    .vid-header {
      display: none;
    }
    .vid-body {
      top: 0;
      height: 100%;
    }
    .vid-controls {
      top: 0;
      right: 0;
      z-index: 10;
    }
    &.left {
      .vid-controls {
        right: 0;
      }
    }
  }

  &.vid-full {
    .vid-controls {
      display: none;
    }
  }

  &.minimized {
    .vid-body,
    .vid-controls {
      display: none;
    }
    .vid-header {
      .icon-minimize {
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -9px 0 0 -2px;
          width: 3px;
          height: 18px;
          background: #fff;
          border-bottom-left-radius: 2px;
          border-top-left-radius: 2px;
          border-bottom-right-radius: 2px;
          border-top-right-radius: 2px;
        }
      }
    }
  }
  &.stethoscope-box {
    .vid-body {
      .OT_video-poster {
        display: none !important;
      }
      .OT_audio-level-meter__audio-only-img {
        display: none !important;
      }
      .OT_audio-level-meter {
        z-index: 2;
      }
      &:after {
        position: absolute;
        content: $icon-stethoscope;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.8);
        font-family: "icomoon";
        font-size: 100px;
        text-align: center;
        line-height: 220px;
        color: $color-icon-light;
      }
    }
  }
}

.vid-box-float {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: bottom;
  margin: 0 0 40px 40px;
  width: 250px;
  height: auto;

  .vid-header {
    z-index: 3;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    padding: 10px;

    a {
      display: inline-block;
      font-size: 22px;
      color: $color-white;

      &:hover {
        color: darken($color-white, 10%);
        text-decoration: none;
      }
    }
  }

  .vid-body {
    z-index: 1;
    position: relative;
    height: 185px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.5);
    border-bottom: none;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.3);
  }

  .vid-name {
    z-index: 2;
    position: relative;
    padding-top: 4px;

    span {
      display: block;
      font-weight: 400;
      font-size: 0.775em;
      color: $color-white;
      text-transform: uppercase;
    }
  }
}

.vid-box-centered {
  position: absolute;
  top: 100px;
  left: 50%;
  width: 350px;
  margin-left: -175px;
}

.spectator-panel {
  .vid-half,
  .vid-full {
    top: 120px !important;
  }
  .stethoscope-box {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    height: 60px;
    width: 100%;
    padding: 0;
    margin: 0;
    border-top: 1px solid rgba($color-white, 0.2);
    @include border-top-radius(0);
    @include border-bottom-radius(0);
    .vid-header {
      .device-name {
        font-size: 1.15em;
        line-height: 60px;
      }
      button {
        display: none;
      }
    }
    .vid-body {
      display: none;
      .OT_video-poster {
        display: none !important;
      }
      .OT_audio-level-meter__audio-only-img {
        display: none !important;
      }
      .OT_audio-level-meter {
        z-index: 2;
      }
    }
    .vid-controls {
      top: 15px;
      margin-right: 10px;
      button,
      i {
        margin-top: 0;
        margin-right: 15px;
      }

      .audioLevel {
        height: 30px;
        width: 8px;
        top: 0;
        display: flex;
        flex-direction: column-reverse;
        position: absolute;
        right: 0;
        .level {
          width: 5px;
          height: 5px;
          margin-bottom: 2px;
          @include border-top-radius(50%);
          @include border-bottom-radius(50%);
          transition: background-color 0.5s linear;

          &.full {
            background-color: $color-connect-green;
          }
        }
      }
    }
  }
}

.vid-brand {
  position: absolute;
  bottom: -40px;
  right: 0;
  img {
    height: 30px;
    vertical-align: middle;
  }
  span {
    display: inline-block;
    font-size: 14px;
    line-height: 30px;
    vertical-align: middle;
    color: $color-dark-grey;
  }
}

.meeting-controls {
  z-index: 1001;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  .mc-btn {
    width: 42px;
    height: 42px;
    text-align: center;
    color: $color-doc-brand;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 50%;
    pointer-events: all;
    margin: 5px 10px;

    i {
      line-height: 42px;
      font-size: 20px;
    }

    &:hover {
      background: rgba(255, 255, 255, 1);
      text-decoration: none;
    }
  }

  .mc-end-call {
    color: $color-white;
    background: rgba($color-danger, 0.75);

    i {
      line-height: 40px;
      font-size: 22px;
    }

    &:hover {
      background: rgba($color-danger, 1);
    }
  }

  .mc-chat {
    display: block;

    i {
      font-size: 18px;
    }
  }
}

.control-panel {
  padding: 0;
  margin: 0;
  z-index: 10;
  position: absolute;
  top: 60px + $main-margin;
  left: 12px;
  li {
    position: relative;
    padding: 0;
    margin: 0;
    list-style: none;
    > button {
      position: relative;
      @extend .clean-input;
      display: block;
      width: 180px;
      height: 50px;
      margin: 0 0 10px 0;
      padding: 0 0 0 60px;
      text-align: left;
      color: $color-white;
      background-color: rgba($color-true-black, 0.6);
      @include border-top-radius(25px);
      @include border-bottom-radius(25px);
      @extend .box-shad-main;
      &:hover {
        background-color: rgba($color-true-black, 0.75);
      }
      &.clicked {
        background-color: rgba($color-mz-blue, 0.4);
      }
      &.active {
        background-color: $color-mz-blue;
        &:hover {
          background-color: rgba($color-mz-blue, 0.75);
        }
      }
      &.end-call {
        background-color: $color-mz-red;
        &:hover {
          background-color: rgba($color-mz-red, 0.85);
        }
      }
      i {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 50px;
        height: 100%;
        font-size: 22px;
        background-color: rgba($color-true-black, 0.15);
        line-height: 50px;
        text-align: center;
        @include border-left-radius(25px);
        padding-left: 5px;
      }
    }
  }
}

.meeting-message {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  padding-top: 100px;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  .camera-hide {
    display: none;
    width: 1px;
    height: 1px;
  }
  .message-alert-box {
    max-width: 320px;
    padding: 20px;
  }

  .mm-allow-access {
    color: $color-doc-brand;
    padding: 0 20px;

    i {
      font-size: 70px;
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 18px;
    }

    h1 {
      margin-bottom: 6px;
    }

    .dash {
      margin-right: 8px;
    }

    p {
      font-size: 0.9em;
      line-height: 1.5;
    }

    @media only screen and (max-width: 480px) {
      h1 {
        font-size: 1.5em;
      }
    }
  }

  .survey {
    display: table-cell;
    vertical-align: middle;
    color: darken($color-doc-brand, 5%);

    .input-field {
      width: 100%;
      .label {
        width: 29%;
        float: left;
        clear: both;
        font-size: large;
        text-align: left;
      }
      input {
        width: 70%;
        margin-bottom: 3%;
      }
    }
    .question {
      margin-bottom: 40px;
      label {
        display: block;
        font-size: 1.563em;
        margin-bottom: 10px;
      }
      textarea {
        @extend .clean-input;
        width: 500px;
        height: 50px;
        padding: 8px;
        background: rgba(255, 255, 255, 0.65);
        font-size: 0.9em;
        resize: none;
        overflow-y: scroll;
        border: 1px solid rgba(255, 255, 255, 0.65);
        @include border-top-radius($border-radius);
        @include border-bottom-radius($border-radius);
        &:focus {
          background: rgba(255, 255, 255, 0.8);
          border-color: $color-mz-blue;
        }
      }
    }
    button {
      background: $color-doc-brand;
      font-size: 1.5em;
      padding: 10px;
      height: 50px;
      font-size: 1.2em;
      width: 250px;
      text-align: center;
      margin: 1%;
      @include border-top-radius($border-radius);
      @include border-bottom-radius($border-radius);
      &:hover {
        background: lighten($color-doc-brand, 5%);
      }
      &.btn-submitting {
        background: lighten($color-doc-brand, 30%);
        color: #fff;
      }
    }
  }
}

.payment-prompt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 80px 20px;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;

  .prompt-body {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: bottom;
    margin: 30px 0 0;
    max-width: 640px;
    width: 100%;
    min-height: 300px;
    height: auto;
    background: $color-white;
    padding: 20px 20px 40px;
    @extend .box-shad-main;
    @include border-top-radius($border-radius);
    @include border-bottom-radius($border-radius);

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      display: table;
      width: 100%;
      height: auto;
      height: 6px;
      background: $color-doc-brand;
      @include border-top-radius(0);
      @include border-bottom-radius($border-radius);
    }

    h4 {
      font-size: 1.2em;
      line-height: 1.4;
      margin: 20px 0;
    }

    .message {
      max-width: 300px;
      margin: 0px auto 30px;
      padding: 6px;
      font-size: 1em;
      line-height: 1.4;

      &.danger {
        border-bottom: 1px solid $color-danger;
        color: $color-danger;
      }

      &.info {
        border-bottom: 1px solid $color-info;
        color: $color-info;
      }

      span {
        line-height: 1, 4;
      }
    }

    .btn-large {
      width: 200px;
      font-size: 100%;
    }
  }
}

.mm-wrap {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: bottom;
  margin: 15px auto 0;
  width: 640px;
  height: auto;
  background: $color-white;
  padding: 20px;
  @extend .box-shad-main;
  @include border-top-radius($border-radius);
  @include border-bottom-radius($border-radius);
}

.mm-call-duration {
  text-align: left;
  padding-right: 100px;

  p {
    display: inline-block;
  }

  .mm-session {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    line-height: 55px;
    margin-right: 15px;
    font-size: 90%;
    font-weight: 400;
    em {
      font-style: normal;
    }
  }
}

.mm-rate-call {
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: table;
    width: 100%;
    height: auto;
    height: 6px;
    background: $color-doc-brand;
    @include border-top-radius(0);
    @include border-bottom-radius($border-radius);
  }

  img.brand {
    max-width: 200px;
    max-height: 100px;
    margin-bottom: 30px;
  }
  .mm-info {
    width: auto;
    background: red;
  }
  .star-wrap {
    text-align: center;
    margin-top: 10px;
    i {
      display: inline-block;
      vertical-align: middle;
      font-size: 30px;
      color: $color-dark-grey;
      margin: 5px;

      &:after {
        content: "\ece3";
      }

      &:hover,
      &.active {
        cursor: pointer;
        color: $color-warning;

        &:after {
          content: "\ece5";
        }
      }
    }
  }
  .dash {
    margin-right: 8px;
  }

  > p {
    font-size: 1.25em;
    line-height: 1.3;
    text-transform: uppercase;
    margin-top: 15px;

    &.small {
      font-size: 0.9em;
      line-height: 1.6;
      text-transform: none;
      margin-top: 40px;
      color: $color-dark-grey;

      a {
        display: inline-block;
        color: $color-dark-grey;
        text-decoration: underline;
      }
    }
  }

  .mm-comment {
    margin-top: 30px;

    p {
      margin-bottom: 10px;
    }

    textarea {
      display: block;
      width: 370px;
      height: 100px;
      padding: 15px;
      font-family: inherit;
      margin: 0 auto 15px;
      @include border-top-radius($border-radius);
      @include border-bottom-radius($border-radius);
      border-color: $color-doc-brand;
      font-size: 1em;
    }
    button {
      display: block;
      width: 410px;
      margin: 0 auto;
    }
  }
}

.mm-precall {
  position: relative;
  width: 100%;
  height: 100vh;

  .mm-precall-circle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px $color-doc-brand-sec;
    margin-left: -60px;
    margin-top: -60px;
  }

  .mm-precall-line-mask {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    overflow: hidden;
    -webkit-transform-origin: 60px 60px;
    -moz-transform-origin: 60px 60px;
    -ms-transform-origin: 60px 60px;
    -o-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
    -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
    -webkit-animation: rotation 1.2s infinite linear;

    .mm-precall-line {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      box-shadow: inset 0 0 0 1px rgba(241, 241, 241, 1);
    }
  }

  .mm-precall-text {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-left: -60px;
    margin-top: -60px;
    font-size: 0.8em;
    font-weight: bold;
    line-height: 1.3;

    span {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@media only screen and (max-width: 768px) {
  .meeting-holder-enter {
    top: 80px;
    height: calc(100vh - 80px);
    overflow: scroll;
    overflow-x: hidden;

    .vid-swap {
      display: block;
    }

    .video-box-panel {
      position: initial;
      text-align: center;
      padding: 25px 20px 60px;

      .vid-box {
        position: relative;
        top: 0;
        left: 0;
        margin: 0 auto;
        background: none;
        box-shadow: none;
      }

      .vid-message {
        position: initial;
      }

      .vid-logo {
        position: initial;
        height: auto;
      }
    }
  }

  .video-box-panel .enter-meeting .vid-controls {
    top: initial;
  }
}

@media screen and (max-width: 480px) {
  .meeting-controls .mc-chat {
    display: block;
  }

  //This fixes over flow error on android mobile
  .video-box-panel .vid-box-float,
  .meeting-controls {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .video-box-panel .vid-box-float.vid-sec {
    width: 20vw;
    top: 80px;
    bottom: initial;
    margin: 0;
    left: 20px;

    .vid-body {
      height: 100px;
    }
  }
}
