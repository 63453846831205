.sp-hero-top {
  background-image: url(https://docto-files.s3-ap-southeast-2.amazonaws.com/dermatology-hero.jpg);
  @media (min-width: 1200px) {
    margin-top: 80px;
  }
}

.sp-hero-mid {
  background-image: url(https://docto-files.s3-ap-southeast-2.amazonaws.com/specialty-phone-hero.jpg);
}

.sp-hero {
  padding: 100px 0;
  background-color: #444;
  background-size: cover;
  background-position: center center;
  color: $color-white;

  h1,
  h2 {
    font-size: 46px;
    color: $color-white;
    font-weight: 400;
    margin-bottom: 10px;
    line-height: 1;
  }

  h4 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: normal;
    color: $color-white;
    margin-bottom: 25px;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 0;
  }

  .book-now-block {
    max-width: 280px;
    margin: 0 auto;

    .book-now-body {
      padding: 20px 20px 30px;
      text-align: center;
      text-align: center;
      background: rgb(26, 134, 161);
      background: linear-gradient(
        90deg,
        rgba(26, 134, 161, 0.9) 0%,
        rgba(34, 65, 118, 0.8) 100%
      );
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;

      h3 {
        color: $color-white;
        font-size: 28px;
        margin-bottom: 15px;
      }

      p {
        color: $color-white;
        font-size: 16px;
      }
    }

    .book-now-button {
      button {
        display: block;
        width: 100%;
        background-color: $color-danger-flat;
        padding: 15px 20px;
        color: $color-white;
        text-transform: uppercase;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;

        &:hover {
          background-color: darken($color-danger-flat, 5%);
        }
      }
    }
  }
}

.sp-section-white {
  padding: 50px 0 0;
  background-color: #fff;

  .sp-section-white-info {
    margin-bottom: 50px;
  }

  .mock-bottom {
    text-align: center;

    img {
      width: 100%;
      max-width: 260px;
    }
  }

  h2 {
    font-size: 46px;
    color: $color-dark-grey;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 1.2;
  }

  p {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    margin-bottom: 0;
    color: $color-dark-grey;
  }

  ul {
    margin: 35px 0 0;
    padding: 0;
    list-style: none;

    li {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.6;
      margin-bottom: 12px;
      color: $color-dark-grey;
      display: flex;
      align-items: center;
      &:before {
        background: url("../../assets/images/check-circle-red.svg");
        content: " ";
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        margin-right: 5px;
      }

      img {
        width: 24px;
        margin-right: 8px;
      }
    }
  }
}

.sp-section-blue {
  padding: 100px 0;
  background: url("../../assets/images/spec-logo-bg.png"), #252665; /* Old browsers */
  // background: url('../../assets/images/spec-logo-bg.png'),
  // 	-moz-linear-gradient(-45deg, #16a3b3 0%, #252665 100%); /* FF3.6-15 */
  // background: url('../../assets/images/spec-logo-bg.png'),
  // 	-webkit-linear-gradient(-45deg, #16a3b3 0%, #252665 100%); /* Chrome10-25,Safari5.1-6 */
  // background: url('../../assets/images/spec-logo-bg.png'),
  // 	linear-gradient(135deg, #16a3b3 0%, #252665 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-position: left top;
  background-repeat: no-repeat;
  text-align: center;

  h2 {
    text-align: center;
    font-size: 40px;
    font-weight: 500;
    color: $color-white;
  }

  p {
    max-width: 820px;
    margin: 20px auto;
    text-align: center;
    font-size: 22px;
    font-weight: 300;
    color: $color-white;
  }
  .btn-round-white {
    display: inline-block;
    height: 45px;
    margin: 30px auto 0;
    padding: 0 40px;
    background-color: $color-white !important;
    border-radius: 24px;
    line-height: 45px;
    color: $color-doc-brand !important;
    font-size: 18px;
    text-transform: uppercase;
  }
}

.sp-section-faq {
  padding: 100px 0;
  background-color: $color-white;

  h2 {
    font-size: 46px;
    color: $color-dark-grey;
    font-weight: bold;
    margin-bottom: 35px;

    span {
      font-weight: 300;
    }
  }

  .faq-item {
    margin-bottom: 25px;
    padding-right: 35px;

    .faq-item-question {
      position: relative;
      font-size: 16px;
      margin-bottom: 15px;
      padding-right: 25px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 12px;
        height: 12px;
        background-image: url(../../assets/images/plus-grey.svg);
        background-repeat: no-repeat;
      }
    }

    .faq-item-answer {
      display: none;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.5;
      margin-bottom: 50px;
    }

    &.active {
      .faq-item-answer {
        display: block;
      }

      img {
        display: none;
      }
    }
  }
}

.sp-section-files {
  .form-control.PhoneInput {
    width: 100%;
    margin: auto;
    .PhoneInputInput {
      border: none;
      font-size: 14px;
      margin-bottom: 0;
      border-bottom: 2px solid #13bec4;
      background: #f9f9f9;
      padding: 5px;
    }
  }
  .sf-wrap {
    max-width: 420px;
    margin: 100px auto;
    text-align: center;
    text-align: center;
    background: #1a86a1;
    background: $color-white;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
    &-head {
      padding: 20px 20px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      background-color: rgba(0, 0, 0, 0.025);
      margin-bottom: 20px;

      h4 {
        color: $color-doc-brand;
        font-weight: bold;
        margin-bottom: 0;
      }
    }

    &-input {
      padding: 10px 20px 0;
      padding-bottom: 10px;

      label {
        text-align: left;
        font-weight: 500;
        font-size: 14px;
      }
      select.medium {
        border: none;
        font-size: 14px;
        border-bottom: 2px solid $color-doc-brand-sec;
        padding: 5px;
        height: 40px !important;
      }
      input {
        border-radius: 0;
        border: none;
        border-bottom: 2px solid $color-doc-brand-sec;
        background: #f9f9f9;
        padding: 5px 15px;
        font-weight: 400;
        font-size: 14px;

        &:hover,
        &:focus {
          border-bottom: 2px solid $color-doc-brand;
          box-shadow: none;
        }
      }
      &.phone {
        padding-bottom: 20px;
      }
    }

    &-list {
      margin-top: 10px;
      padding: 0px 20px 20px;
      text-align: left;
    }

    &-drop {
      display: flex;
      flex-direction: column;
      margin: 0 20px 10px;
      align-items: center;
      text-align: center;
      justify-content: center;
      border: 2px dashed rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      border-radius: 10px;
      padding-top: 20px;
      i {
        font-size: 40px;
      }

      span,
      a {
        display: block;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        &.as-link {
          text-decoration: underline;
          color: #1a86a1;
        }
      }
    }

    &-footer {
      padding: 0 20px 20px;
      width: 100%;

      button {
        width: 100%;
        background-color: $color-doc-brand-sec;
        color: $color-white;
        border-radius: 4px;
        text-align: center;
        padding: 10px 15px;

        &:hover {
          background-color: darken($color-doc-brand-sec, 5%);
        }
        &:disabled {
          background: #444;
        }
      }
    }
    &-label {
      background: none;
      display: block;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;
      padding: 5px;
    }
  }

  .file-item-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    padding: 0px 20px;
    .type-icon {
      font-size: 22px;
      margin-right: 5px;
      margin-bottom: 2px;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .f-right {
      margin-left: auto;
    }

    .icon-check {
      color: $color-success;
      font-size: 22px;
    }

    .progress-number {
      color: lighten($color-warning, 25%);
    }

    .loading-bar {
      position: absolute;
      top: 100%;
      right: 0;
      width: calc(100% - 25px);
      height: 5px;
      background-color: rgba(0, 0, 0, 0.025);
      border: 1px solid rgba(0, 0, 0, 0.025);
      border-radius: 4px;
      margin-left: auto;
      margin-top: 2px;

      .loading-bar-inner {
        width: 0%;
        height: 3px;
        border-radius: 3px;
        background-color: lighten($color-warning, 25%);
      }
    }
  }
}

//Show all when edit is active
.specialty-landing {
  .public-url {
    padding: 15px 0px;
    font-size: 17px;
  }
  .edit-holder {
    z-index: 9999;
    position: fixed;
    min-height: 130px;
    top: 0;
    width: 100%;
    background-color: $color-blueish-grey;
    border-bottom: 1px solid $color-mid-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    flex-direction: column;

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      margin-top: 10px;
    }
    .left-header {
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .slug-url {
        margin-left: 16px;
        color: black;
        margin-top: 10px;
        display: flex;
        align-items: baseline;
      }
    }
    .seo-information {
      width: 100%;
      display: flex;
      flex-direction: column;
      .seo-title {
        font-size: medium;
        padding: 10px 0px;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .input-holder {
        display: flex;
        flex-direction: row;
        margin: 10px 0px;
        &.right {
          justify-content: flex-end;
          margin-right: 15px;
        }
        .input-group {
          display: flex;
          flex-direction: row;
          width: 100%;
          &.tags {
            flex-direction: column;
          }
        }
        .input-group-50 {
          display: flex;
          flex-direction: row;
          width: 50%;
        }
        label {
          margin: 5px 15px;
          color: white;
        }
        input {
          border-radius: 5px;
          margin: 0px 15px;
          height: 40px;
        }
      }

      .seo-open {
        padding: 15px;
        background: #afbcbd;
        border-radius: 10px;
        margin: 10px;
        .tag-input {
          width: 100%;
          display: flex;
        }
      }
    }
    h2.page-title {
      margin-right: auto;
      color: black;
      font-size: 20px;
      letter-spacing: -1px;
      font-weight: 500;
      padding: 10px;
      margin: 0px;
      width: 100%;
    }

    button,
    a {
      padding: 0 15px;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 10px;
      height: 32px;
      cursor: pointer;

      i {
        margin-left: 5px;
      }

      &.edit-publish {
        background-color: $color-edit-purple;
        color: $color-white;

        &:hover {
          background-color: darken($color-edit-purple, 5%);
        }
      }

      &.edit-published {
        background-color: $color-success;
        color: $color-white;

        &:hover {
          background-color: darken($color-success, 5%);
        }
      }
      &.edit-preview {
        padding: 8px 15px;
        font-weight: 500;
        background-color: $color-chat-blue;
        color: $color-white;
        border-radius: 4px;
        font-size: 14px;
        margin-left: 10px;
        height: 32px;
        cursor: pointer;

        &:hover {
          background-color: darken($color-chat-blue, 5%);
        }
      }
      &.back-button {
        background: #cc0d0d;
        cursor: pointer;
        color: white;
        padding: 8px;
        width: 30px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
      }
      &.open-seo {
        background: #091869;
        cursor: pointer;
        color: white;
        padding: 8px;
        width: 30px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        i {
          margin: 0px;
        }
      }
      &.stop-edit {
        background: red;
        cursor: pointer;
        color: white;
      }

      &.edit {
        background: rgb(38, 170, 131);
        cursor: pointer;
        color: white;
      }
      &.save {
        border-radius: 5px;
        background: rgb(165, 23, 158);
        cursor: pointer;
        color: white;
      }
      &.add {
        margin: 10px;
        background: #25ab82;
        color: white;
      }
    }

    .action-holder {
      min-width: 300px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
    .change-slug-div {
      display: flex;
      align-items: center;
      .slug-input {
        margin: 0px 15px;
        border-radius: 5px;
        height: 40px;
      }
    }
  }
}

.edit-mode {
  position: relative;

  .edit-mode-header {
    z-index: 9999;
    position: fixed;
    height: 80px;
    width: 100%;
    background-color: $color-blueish-grey;
    border-bottom: 1px solid $color-mid-grey;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;

    h2 {
      margin-right: auto;
      color: $color-edit-purple;
      font-size: 26px;
      letter-spacing: -1px;
    }

    button {
      padding: 0 15px;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 10px;
      height: 32px;
      cursor: pointer;

      i {
        margin-left: 5px;
      }

      &.edit-publish {
        background-color: $color-edit-purple;
        color: $color-white;

        &:hover {
          background-color: darken($color-edit-purple, 5%);
        }
      }

      &.edit-published {
        background-color: $color-success;
        color: $color-white;

        &:hover {
          background-color: darken($color-success, 5%);
        }
      }

      &.edit-preview {
        background-color: $color-chat-blue;
        color: $color-white;

        &:hover {
          background-color: darken($color-chat-blue, 5%);
        }
      }
    }
  }

  .editable-block {
    box-shadow: 0px 0px 2px #9d08fb;
    position: relative;
    padding-right: 20px;
    border-radius: 4px;
    cursor: pointer;

    &:focus {
      box-shadow: 0px 0px 4px #9d08fb;
    }

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 20px;
      height: 20px;
      background: rgba(158, 9, 251, 0.4);
      border-top-right-radius: 4px;
      border-bottom-left-radius: 10px;
    }

    &:after {
      content: "\e60d";
      position: absolute;
      z-index: 2;
      top: 4px;
      right: 4px;
      display: block;
      width: 10px;
      height: 10px;
      color: #fff;
      font-size: 10px;

      font-family: "icomoon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  .change-picture {
    /* width: 400px; */
    display: flex;
    justify-content: flex-end;
    padding: 10px 40px;
    input {
      visibility: hidden;
    }
  }
  .revert-image-input {
    display: inline-block;
    position: relative;
    cursor: pointer;
    z-index: 2;
    width: 50px;
    height: 50px;
    left: 30px;
    margin-left: -25px;
    margin-top: -12px;
    color: #fff;
    font-size: 25px;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 50px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgba(251, 57, 9, 0.993);
    border-radius: 25px;
    text-align: center;
  }
  .editable-image-input {
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:before {
      content: "\e60d";
      position: absolute;
      z-index: 2;
      display: block;
      width: 50px;
      height: 50px;
      left: -30px;
      margin-left: -25px;
      margin-top: -12px;
      color: #fff;
      font-size: 25px;
      font-family: "icomoon" !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 50px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background: rgba(158, 9, 251, 0.8);
      border-radius: 25px;
      text-align: center;
    }
  }

  .editable-bg-image {
    position: relative;

    .edit-trigger {
      content: "";
      position: absolute;
      z-index: 1;
      display: block;
      width: 50px;
      height: 50px;
      top: 5px;
      right: 5px;
      background: rgba(158, 9, 251, 0.8);
      border-radius: 25px;
      cursor: pointer;

      &:after {
        content: "\e60d";
        display: block;
        width: 50px;
        height: 50px;
        color: #fff;
        font-size: 25px;
        text-align: center;

        line-height: 50px;

        font-family: "icomoon" !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }

  .sp-section-faq {
    .faq-item-answer {
      display: block !important;
    }
  }
}
