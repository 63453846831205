.circle-btn {
  @extend .clean-input;
  width: 30px;
  height: 30px;
  display: inline-block;
  font-size: 18px;
  @include border-top-radius(50%);
  @include border-bottom-radius(50%);
  @extend .box-shad-main;
  vertical-align: middle;
}

.circle-btn-yellow {
  @extend .circle-btn;
  color: $color-true-black;
  background-color: $color-mz-yellow;

  &:hover {
    background-color: darken($color-mz-yellow, 7.5%);
  }
}

.circle-btn-green {
  @extend .circle-btn;
  color: $color-white;
  background-color: $color-success;

  &:hover {
    background-color: darken($color-success, 7.5%);
  }
}

.circle-btn-red {
  @extend .circle-btn;
  color: $color-white;
  background-color: $color-danger;

  &:hover {
    background-color: darken($color-danger, 7.5%);
  }
}

.microphone {
  @include create-icon($icon-microphone-off, $icon-microphone);
}

.camera {
  @include create-icon($icon-camera-off, $icon-camera);
}

.volumen {
  @include create-icon($icon-mute, $icon-volume-high);
}

.cross {
  font-size: 14px;
  @include create-icon($icon-cross, $icon-cross);
}

.check {
  font-size: 14px;
  @include create-icon($icon-check, $icon-check);
}

.help {
  @include create-icon($icon-question, $icon-question);
}

[class^="btn-"],
[class*="btn-"] {
  min-height: 30px;
  line-height: 30px;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  padding: 0 10px;
  @include border-top-radius($border-radius);
  @include border-bottom-radius($border-radius);
  box-shadow: none;
  cursor: pointer;

  > * {
    display: inline-block;
    line-height: 30px;
    height: 30px;
    vertical-align: middle;
  }

  i {
    font-size: 18px;
    margin-left: 3px;
  }

  &.disabled {
    background: $color-dark-grey !important;
    cursor: not-allowed;

    &:hover {
      text-decoration: none;
    }
  }
}

.btn-large {
  line-height: 40px;
  height: 40px;
  font-size: 1em;
}

.btn-small {
  line-height: 24px;
  height: 24px;
  min-height: 24px;
  font-size: 0.8em;
}

.btn-green {
  background-color: $color-success;
  color: #fff;

  &:hover {
    background-color: darken($color-success, 7.5%);
    color: #fff;
  }
}

.btn-purple {
  background-color: $color-doc-brand;
  color: #fff;

  &:hover {
    background-color: darken($color-doc-brand, 7.5%);
    color: #fff;
  }
}

.btn-grey {
  background-color: $color-dark-grey;
  color: #fff;

  &:hover {
    background-color: darken($color-dark-grey, 7.5%);
    color: #fff;
  }
}

.btn-blue {
  background-color: $color-info;
  color: #fff;

  &:hover {
    background-color: darken($color-info, 7.5%);
    color: #fff;
  }
}

.btn-warning {
  background-color: $color-warning;
  color: #fff;

  &:hover {
    background-color: darken($color-warning, 7.5%);
    color: #fff;
  }
}

.btn-info {
  background-color: $color-doc-brand-sec;
  color: #fff;

  &:hover {
    background-color: darken($color-doc-brand-sec, 7.5%);
    color: #fff;
  }
}

.btn-blue {
  padding: 0 15px;
  line-height: 40px;
  display: block;
  background: $color-doc-brand;
  border-color: $color-doc-brand;
  text-transform: none;

  &:hover {
    background: darken($color-doc-brand, 7%);
    border-color: darken($color-doc-brand, 7%);
    color: #fff;
  }
}

.btn-black {
  padding: 0 15px;
  line-height: 40px;
  display: block;
  background: $color-black-grey;
  border-color: $color-black-grey;
  text-transform: none;
  color: #fff;

  &:hover {
    background: darken($color-black-grey, 7%);
    border-color: darken($color-black-grey, 7%);
    color: #fff;
  }

  &.disabled {
    background: $color-black-grey !important;
    opacity: 0.4;
  }
}

.btn-red {
  border-color: $color-danger;
  background-color: $color-danger;
  color: #fff;

  &:focus,
  &:active,
  &:hover {
    border-color: darken($color-danger, 7.5%);
    background-color: darken($color-danger, 7.5%);
    color: #fff;
  }
}

.btn-white {
  background-color: $color-white;
  color: #525252;

  &:hover {
    background-color: darken($color-white, 7.5%);
    color: #525252;
  }
}

.fileselector {
  label {
    width: auto !important;
    min-height: 34px;
    line-height: 34px;
    display: inline-block;
    font-size: 1;
    font-weight: 400;
    padding: 0 10px;
    @include border-top-radius($border-radius);
    @include border-bottom-radius($border-radius);
    box-shadow: none;
    cursor: pointer;
    background: $color-doc-brand-sec;
    color: $color-white;

    &:hover {
      background-color: darken($color-doc-brand-sec, 7.5%);
    }
  }

  .bigger {
    width: 50% !important;
  }

  .upload-file-selector {
    display: none !important;
  }

  i {
    margin-right: 6px;
  }
}

.form-styled {
  min-height: 30px;
  line-height: 30px;
  display: inline-block;
  font-size: 0.8em;
  font-weight: 400;
  padding: 0 10px;
  @include border-top-radius($border-radius);
  @include border-bottom-radius($border-radius);
  box-shadow: none;
  cursor: pointer;
  background-color: $color-white;
  border: 1px solid rgba(22, 23, 56, 0.22);
  padding: 0 10px;
  color: $color-input-text;
}
