// Register

.register-holder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 80px 0;
  text-align: center;
  overflow: hidden;
  overflow-y: auto;

  .reg-logo {
    text-align: center;
  }

  .reg-body {
    position: relative;
    z-index: 1;
    display: inline-block;
    vertical-align: bottom;
    margin: 30px 0 0;
    width: 640px;
    min-height: calc(100vh - 200px);
    height: auto;
    background: $color-white;
    padding: 20px 20px 40px;
    @extend .box-shad-main;
    @include border-top-radius($border-radius);
    @include border-bottom-radius($border-radius);

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: "";
      display: table;
      width: 100%;
      height: auto;
      height: 6px;
      background: $color-doc-brand;
      @include border-top-radius(0);
      @include border-bottom-radius($border-radius);
    }

    .telehealth-tag {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px;
      background: rgba(0, 0, 0, 0.1);
      color: $color-doc-brand;
      font-size: 0.875em;
      border-top-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;

      i {
        display: inline-block;
        margin-right: 4px;
        vertical-align: bottom;
      }
    }

    h1 {
      margin: 10px auto 10px;
    }

    h2 {
      font-size: 1.5em;
      line-height: 1.3;
      margin: 30px auto 15px;
    }

    .header-lined {
      width: 120px;
      height: 4px;
      margin: 10px auto 30px;
      background: $color-mid-grey;
    }

    .profile-img {
      text-align: center;
      margin-bottom: 30px;

      .profile-avatar {
        width: 120px;
        height: 120px;
        border-radius: 60px;
        margin: 0 auto;
        background-color: rgba(0, 0, 0, 0.1);
        background-size: cover;
        background-position: center top;

        .loader-animation {
          width: 120px;
          height: 120px;
          border-radius: 60px;
        }
      }

      .edit-avatar {
        display: inline-block;
        margin: 10px auto 0;
        font-size: 0.875em;
        text-align: center;

        .loader-animation {
          width: 120px;
          height: 8px;
        }
      }

      img {
        width: 150px;
      }
    }

    hr.divider {
      width: 450px;
      margin: 30px auto;
    }

    .message {
      margin: 0 60px 30px;
      padding: 6px;

      &.danger {
        border-bottom: 1px solid $color-danger;
        color: $color-danger;
      }

      &.info {
        border-bottom: 1px solid $color-danger;
        color: $color-info;
      }

      span {
        line-height: 1, 4;
        font-size: 0.8em;
        text-transform: uppercase;
      }
    }

    .prof-load {
      display: flex;
      width: 100%;
      max-width: 450px;
      margin: 0 auto 12px;
      justify-content: space-between;

      .loader-animation {
        height: 8px;
      }

      .left {
        width: 30%;
      }
      .right {
        width: 65%;
      }

      .wide {
        width: 100%;
        max-width: 450px;
      }
    }

    .form-group {
      margin-bottom: 10px;
      position: relative;
      &.phone {
        display: flex;
        justify-content: space-around;
        width: 471px;
        margin: auto;
        margin-bottom: 10px;
        position: relative;
        .PhoneInput {
          width: 280px;
        }
      }

      label {
        display: inline-block;
        width: 125px;
        text-align: right;
        margin-right: 5px;
        font-size: 0.9em;
        vertical-align: middle;
      }

      .col-1-4 {
        width: 75px;
        margin-right: 5px;
      }

      .col-3-4 {
        width: 240px;
      }

      input,
      select,
      .selectWrap,
      .select,
      textarea,
      .Select-control,
      .password-input {
        display: inline-block;
        font: inherit;
        width: 320px;
        padding: 0 10px;
        vertical-align: middle;
        outline: none;
        border-radius: 3px;
        height: 40px;
        font-size: 0.825em;
        background: rgba($color-doc-brand, 0.04);
        border: 1px solid rgba($color-doc-brand, 0.15);

        &:focus {
          border: 1px solid rgba($color-doc-brand, 0.35);
        }

        &[type="checkbox"] {
          width: auto;
          height: auto;
        }
      }
      .selectWrap {
        max-height: 75px;
        background-color: transparent;
        border: none;
        margin-bottom: 20px;
      }
      .docto {
        &__input {
          height: 20px;
        }
      }
      .docto__value-container.docto__value-container--is-multi.docto__value-container--has-value.css-g1d714-ValueContainer {
        max-height: 50px;
        overflow: scroll;
      }
      .react-phone-number-input {
        display: inline-block;
        font: inherit;
        width: 320px;
        vertical-align: middle;
        border: none;
        outline: none;

        .react-phone-number-input__country-select {
          width: 40px !important;
        }

        input[type="tel"] {
          width: 280px;
        }
      }

      .password-input-wrap {
        display: inline-block;
        font: inherit;
        width: 320px;
      }

      .password-input {
        position: relative;

        span {
          display: block;
          padding-top: 7px;
          line-height: 32px;
          text-align: left;
        }

        a.password-change {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
          padding: 0 10px;
          line-height: 38px;
          background: $color-doc-brand-sec;
          border-bottom-right-radius: 3px;
          border-top-right-radius: 3px;
          color: $color-white;
        }
      }

      &.passFix {
        margin-bottom: 60px;
      }

      .password-input-wrap {
        position: relative;

        a.password-confirm {
          position: absolute;
          top: 100%;
          right: 0;
          width: 100%;
          margin-top: 10px;
        }
      }

      select {
        width: 320px;
      }

      textarea {
        padding: 10px;
        vertical-align: text-top;
        resize: none;
        height: 80px;
        line-height: 1.3;
        font-size: 0.8em;
      }

      &:after {
        display: none;
        content: attr(data-alert);
        width: 300px;
        margin: 3px 0 0 134px;
        padding: 6px 10px;

        background: rgba($color-danger, 0.15);

        font-size: 0.75em;
        text-align: left;
        color: $color-danger;

        @include border-top-radius($border-radius);
        @include border-bottom-radius($border-radius);
      }

      &.alert-error {
        &:after {
          display: inline-block;
        }

        input {
          border: 1px solid $color-danger;
        }
      }
      &.alert-success {
        &:after {
          display: inline-block;
          width: 300px;
          margin: 3px 0 0 134px;
          padding: 6px 10px;
          background: #13bec430;
          font-size: 0.75em;
          text-align: left;
          color: $color-doc-brand-sec;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        input {
          border: 1px solid $color-doc-brand-sec;
        }
      }

      &.centered {
        label {
          width: auto;
        }
        &:after {
          content: attr(data-alert);
          width: 322px;
          margin: 3px 0 0 42px;
          padding: 6px 10px;

          background: rgba($color-danger, 0.15);

          font-size: 0.75em;
          text-align: left;
          color: $color-danger;

          @include border-top-radius($border-radius);
          @include border-bottom-radius($border-radius);
        }
      }

      .Select--multi,
      .Select--single {
        display: inline-block;
        width: auto;
        vertical-align: middle;
        text-align: left;

        .Select-control {
          width: 320px;
        }

        .Select-input {
          margin: 0;

          input {
            border: none;
            background: none;
            width: auto;
            padding: 0;
          }
        }

        .Select-value {
          margin: 6px 5px 5px 0;
        }
      }
    }

    .radio-wrap {
      display: inline-block;
      width: 342px;
      text-align: left;
      margin-left: 130px;

      input {
        vertical-align: middle;
      }
    }

    .reg-actions {
      margin-top: 30px;
      text-align: center;

      button {
        width: 180px;
        margin: 0 5px;

        i {
          margin-right: 5px;
          vertical-align: middle;
          font-size: 1.5em;
        }
      }
    }

    .reg-footer {
      padding: 10px 10px 0;
      font-size: 0.8em;
      color: $color-dark-grey;
    }
  }

  .tooltip-mark {
    display: inline-block;
    font-style: normal;
    width: 14px;
    height: 14px;
    background: #262262;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 10px;
    vertical-align: text-top;
    font-weight: bold;
  }
}
