//Base Colors
$color-transparent: rgba(0, 0, 0, 0);
$color-white: #fff;
$color-eclipse: #333;
$color-true-black: #000;
$color-font: #888888;
$color-font-grey: #525252;

//Brand Colors
$color-doc-brand: #161738;
$color-doc-brand-sec: #13bec4;

//Other
$color-mz-dark: #231f20;
$color-mz-green: #c4d934;
$color-mz-red: #f15852;
$color-mz-yellow: #fbd62b;
$color-mz-blue: #5bc4be;
$color-mz-grey: #7d7e7e;

//App Colors
$color-main-bg: #f1f1f1;
$color-top-bar: $color-doc-brand;
$color-side-pannel: #e9e9e9;
$color-border-grey: #dddddd;
$color-superlight-grey: #f6f6f6;
$color-danger: #8a0000;
$color-lighter-grey: #f9f9f9;
$color-light-grey: #f6f4f4;
$color-grey: #b1afaf;
$color-mid-grey: #cccccc;
$color-dark-grey: #878787;
$color-darker-grey: #555555;
$color-black-grey: #030303;
$color-blueish-grey: #e5eced;
$color-mute-grey: #b8b9bc;
$color-light-blue: #29cde2;
$color-connect-green: #0ce535;
$color-connect-orange: #f06305;
$color-connect-red: #ff0000;
$color-vid-box-flat: #292a2a;
$color-icon-light: #e6e6e6;
$color-info: #13579c;
$color-success: #139c30;
$color-danger: #cc0d0d;
$color-danger-flat: #bb2b1f;
$color-warning: #ff9800;
$color-map: #001328;
$color-chat-blue: #0084ff;
$color-input-text: #777777;
$color-notes-a: #f9efaf;
$color-notes-b: #f7e98d;
$color-edit-purple: #ab33e8;

//File Colors
$color-file-pdf: #0f0f0f;
$color-file-jpg: #ef6a65;

//Form
$form-box: #f0f0f0;
$form-box-text: #8b8b8b;
$form-box-text-dark: #0f0f0f;
$form-box-border: #8b8b8b;

//Notificiations
$color-notif-caret: #3c3d54;
$color-notif-bg: #919191;
$color-notif-button: #787878;

//Chat
$color-messages-bg: #ffffff;
$color-messages-border: #f2f2f2;
$color-messages-box: #f6f6f6;
$color-messages-text: #0f0f0f;
$color-messages-text-mute: #818181;
$color-chat-bubble: #f5f5f5;
$color-chat-bubble-sec: #41a0fa;
$color-chat-bubble-system: $color-doc-brand-sec;
$color-chat-panel: #f6f6f6;

//Simple View
$color-sv-alert: #c92d39;
