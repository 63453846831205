//Side Pannel
.side-panel,
.chat-wrap {
  position: absolute;
  z-index: 4;
  top: $top-bar-height;
  right: 0;
  bottom: 0;
  width: 310px;

  .call-actions {
    background-color: #fff;
    padding: 5px 8px 8px 8px;
    display: flex;

    .btn {
      flex: 1;
      vertical-align: middle;
      font: inherit;
      text-align: center;
      border: none;
      margin: 0 10px 0 0;
      cursor: pointer;
      overflow: visible;
      padding: 2px;
      background-color: #21203b;
      border: 2px solid #21203a;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: #fff;

      i {
        margin-right: 3px;
        margin-left: 0;
      }

      &.disabled {
        background: #b7b7b7;
        border-color: #b7b7b7;
        cursor: not-allowed;

        &:hover {
          background: #b7b7b7;
          border-color: #b7b7b7;
          cursor: not-allowed;
        }
      }

      &:last-child {
        margin: 0;
      }
    }

    .btn:hover {
      color: #fff;
      text-decoration: none;
    }

    .btn--primary {
      background-color: #272264;
      border-color: #272264;
    }

    .btn--primary:hover,
    .btn--primary:active,
    .btn--primary:focus {
      background-color: #18153e;
      border-color: #18153e;
      color: #fff;
    }

    .btn--tertiary {
      background-color: #13bec4;
      border-color: #13bec4;
    }

    .btn--tertiary:hover,
    .btn--tertiary:active,
    .btn--tertiary:focus {
      background-color: #0e9196;
      border-color: #0e9196;
    }
  }

  &.side-panel-spectator {
    top: 120px;
  }

  &.minimized {
    width: 60px;

    .header {
      height: 60px;

      i {
        line-height: 60px;
        height: 60px;
        width: 40px;
        font-size: 24px;
        cursor: pointer;
      }

      span {
        display: none;
      }

      .actions {
        display: none;
      }
    }

    .chat-scroll,
    .chat-box {
      display: none;
    }
  }

  .header {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

    .header-upper {
      z-index: 1;
      position: relative;
      display: flex;
      align-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      flex-wrap: wrap;
    }

    .doctor-name {
      font-size: 0.8em;
      font-weight: 300;
    }

    .actions {
      position: absolute;
      top: 0;
      right: 0;

      .minimize {
        @extend .clean-input;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 20px;
      }

      .btn {
        display: inline-block;
        height: 30px;
        margin: 5px 4px 0 0;
        padding: 0 8px;
        background: $color-success;
        font-size: 0.7em;
        line-height: 28px;
        color: $color-white;
        border-radius: $border-radius;

        &:hover {
          background-color: darken($color-success, 7.5%);
        }
      }
    }

    > i {
      position: relative;
      display: inline-block;
      text-align: center;
      font-size: 16px;
      margin-right: 5px;

      &:after {
        content: "";
        position: absolute;
        top: 7px;
        right: 1px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      &.tag-online {
        &:after {
          background: $color-connect-green;
        }
      }

      &.tag-offline {
        &:after {
          background: $color-connect-red;
        }
      }

      em.message-count {
        position: absolute;
        top: 0;
        right: 0;
        width: 16px;
        height: 16px;
        margin: 8px 8px 0 0;
        background-color: $color-mz-red;
        color: $color-white;
        text-align: center;
        line-height: 16px;
        font-style: normal;
        font-size: 11px;
        font-weight: 700;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }

    .header-tittle {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      h1 {
        color: $color-true-black;
        font-size: 2.2em;

        .loader-animation {
          width: 300px;
          height: 32px;
        }

        span {
          font-weight: 300;
        }
      }

      @media only screen and (max-width: 1024px) {
        margin-top: 15px;
        padding-left: 0;
        flex-direction: column;

        h1 {
          font-size: 1.5em;
        }
      }

      @media only screen and (max-width: 880px) {
        padding-left: 15px;
      }

      .ht-date {
        margin-left: 20px;

        @media only screen and (max-width: 1024px) {
          margin-left: 0;
          margin-top: 10px;
        }

        strong,
        span {
          display: block;
          font-size: 1em;
          color: $color-true-black;
          line-height: 1.3;
        }
      }
    }
  }

  .chat-scroll {
    position: relative;
    width: 100%;
    height: calc(100% - 90px);
    overflow: hidden;
    overflow-y: auto;
    padding-top: 20px;
    padding-bottom: 160px;

    @media only screen and (max-width: 880px) {
      padding-bottom: 260px;
    }
  }

  .chat-item {
    z-index: 0;
    position: relative;
    width: auto;
    padding: 0 $main-margin;
    margin: 30px 0;
    text-align: right;

    &.attachment {
      cursor: pointer;
      text-align: right;

      img {
        display: inline-block;
        max-width: 240px;
        margin-bottom: 10px;
      }

      a {
        display: inline-block;
      }
    }

    .ci-time-stamp {
      text-align: right;
      display: block;
      font-size: 0.6em;
      margin: 0px 5px 2px 0;
    }

    .chat-timestamp {
      display: block;
      font-size: 0.625em;
      color: #7d7e7e;
      text-align: right;
      font-weight: 700;
      margin-bottom: 4px;
    }

    .centered {
      text-align: center;
    }

    .chat-bubble {
      position: relative;
      display: inline-block;
      width: auto;
      min-width: 250px;
      padding: 15px 25px;
      background: $color-chat-bubble-sec;
      color: $color-white;
      text-align: left;
      line-height: 1.2;
      font-size: 0.75em;
      @include border-top-radius($border-radius);
      @include border-bottom-radius($border-radius);
    }

    &.received {
      text-align: left;
      padding: 0 $main-margin;

      .chat-bubble {
        background: $color-chat-bubble;
        color: $color-messages-text;
      }

      .ci-time-stamp {
        text-align: left;
        margin-left: 5px;
      }
    }

    &.bubble-green {
      .chat-bubble {
        background: $color-connect-green;
        color: $color-white;
      }
    }

    .chat-user-left {
      text-align: center;
      font-weight: 700;
      font-style: italic;
      font-size: 14px;
    }

    .doc-link {
      display: block;
      margin-top: 2px;
      color: $color-doc-brand-sec;
      font-weight: 400;
      line-height: 1.4;
    }

    &.chat-message-info {
      .chat-bubble {
        background: $color-doc-brand-sec;
        color: $color-white;
        text-align: center;

        > i {
          display: block;
          font-size: 26px;
          margin-bottom: 4px;
        }

        span {
          display: inline-block;
          margin-bottom: 8px;
        }

        &:after {
          display: none;
        }
      }
    }

    .btn-chat {
      background: $color-white;
      padding: 0 8px;
      line-height: 25px;
      min-height: 25px;
      font-size: 0.9em;
      min-width: 100px;
      margin-bottom: 4px;
      font-weight: 500;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      i {
        font-size: 16px;
        margin-right: 4px;
      }
    }
  }

  .chat-box {
    position: relative;
    width: 100%;
    height: 50px;
    background-color: white;
    border-top: 1px solid $color-mid-grey;

    textarea {
      @extend .clean-input;
      box-sizing: border-box;
      resize: none;
      width: 100%;
      height: 30px;
      padding: 8px 58px 8px 58px;
      background-color: $color-white;
      float: left;
      font-size: 0.9em;
      overflow: hidden;
      overflow-y: scroll;
    }

    button,
    input {
      position: absolute;
      z-index: 2;
      top: 0;
      @extend .clean-input;
      width: 50px;
      height: 52px;
      float: right;
      background: $color-doc-brand;
      text-align: center;
      color: $color-white;
      font-size: 25px;
      font-family: "icomoon";

      &.send-button {
        right: 0;

        &:before {
          content: "#{$icon-check}";
        }
      }

      &.add-file-button {
        left: 0;

        &:before {
          content: "#{$icon-paperclip}";
        }
      }

      &.uploading {
        background: $color-white;

        &:hover {
          background: $color-white;
        }
      }

      &:hover {
        background: lighten($color-doc-brand, 10%);
      }
    }
  }
}

.footer-chat {
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 0;

  .chat-wrap {
    position: initial;
    display: inline-block;
    height: auto;
    border: 2px solid #dddddd;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.65);
    margin-right: 20px;
    vertical-align: bottom;

    .doctor-name {
      display: inline-block;
      @include ellipsis(230px);
    }

    .chat-scroll {
      height: 320px;
    }

    .chat-typing {
      text-align: right;
      font-size: small;
      font-style: italic;
      margin-right: 10px;
      margin-bottom: 2px;
    }

    .actions {
      .maximize {
        display: none;
      }
    }

    &.minimized {
      height: 40px;
      width: 310px;

      .header {
        height: 40px;

        span {
          display: inline-block;
        }

        i {
          line-height: 40px;
          height: 40px;
          font-size: 20px;
          width: 25px;
        }

        .actions {
          display: inline-block;

          .minimize {
            display: none;
          }

          .maximize {
            display: inline-block;
          }
        }
      }
    }
  }
}

.pre-chat-message {
  padding: 20px;
  text-align: center;
  a {
    cursor: pointer;
  }

  .btn-purple {
    margin-bottom: 10px;
    width: 120px;
  }

  p {
    font-size: 0.875em;
    list-style: 1.3;
    margin-bottom: 8px;

    b {
      font-weight: 400;
    }
  }
}

/*
  Messages Page
*/
.messages-holder {
  z-index: 1;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-messages-bg;

  .messages-list-pannel {
    width: 100%;
    height: 100vh;
    padding-top: $top-bar-height;
    border-right: 1px solid $color-messages-border;
    background: $color-messages-bg;

    .header {
      z-index: 1;
      position: relative;
      height: 60px;
      width: 100%;
      border-bottom: 1px solid $color-messages-border;

      input[type="text"] {
        @extend .clean-input;
        box-sizing: border-box;
        resize: none;
        width: 100%;
        height: 60px;
        padding: 8px 8px 8px 70px;
        float: left;
        font-size: 1em;
        font-weight: 400;
        font-family: inherit;
        overflow: hidden;
        overflow-y: scroll;
      }

      button {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        @extend .clean-input;
        width: 60px;
        height: 60px;
        padding-right: 5px;
        background-color: transparent;
        text-align: right;
        color: $color-messages-text-mute;
        font-size: 18px;
        font-family: "icomoon";

        &:before {
          content: "#{$icon-search}";
        }

        &:hover {
          background-color: transparent;
        }
      }
    }

    .scroll-wrap {
      height: calc(100vh - 140px);
      overflow: hidden;
      overflow-y: auto;
    }

    .initial-message {
      padding: 10px;

      .im-inner {
        background: rgba($color-chat-bubble-sec, 0.15);
        color: $color-chat-bubble-sec;
        border: 1px solid $color-chat-bubble-sec;
        padding: 0;
        flex: 1;
        max-width: none;
        border-radius: 3px;

        .im-body {
          padding: 10px 15px;

          h4 {
            font-size: 16px;
            border: none !important;
            margin-bottom: 5px;
          }

          p {
            font-size: 14px;

            &:not(:last-child) {
              margin-bottom: 3px;
            }
          }
        }
      }
    }

    .message-preview {
      display: block;
      color: $color-messages-text;
      width: 100%;
      position: relative;
      height: auto;
      padding: 30px 30px 30px 90px;
      border-bottom: 1px solid $color-messages-border;

      &:hover,
      &.active {
        text-decoration: none;
        background: rgba(106, 194, 197, 0.12);
      }

      img,
      .load-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        margin: 30px 0 0 30px;
        border-radius: 50%;
        background: darken($color-border-grey, 7.5%);
      }

      .load-img {
        .loader-animation {
          height: 100%;
          border-radius: 50%;
        }
      }

      .user-info {
        display: flex;
        align-items: center;
        align-content: center;
        margin-bottom: 5px;

        span {
          display: inline-block;
          color: $color-messages-text-mute;
          font-size: 0.8em;
          font-weight: 600;

          .loader-animation {
            width: 120px;
            height: 10px;
          }
        }

        i {
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 5px;
          border-radius: 50%;
          background: $color-font-grey;
        }

        .loader-animation {
        }
      }

      strong {
        font-weight: 800;
        font-size: 1em;
        margin-bottom: 4px;

        .loader-animation {
          width: 150px;
          height: 12px;
        }
      }

      > span,
      em {
        color: $color-messages-text-mute;
        font-size: 0.8em;
        font-weight: 600;

        .loader-animation {
          width: 80px;
          height: 10px;
        }
      }

      strong,
      span,
      em {
        display: block;
        line-height: 1.2;
        @include ellipsis(100%);
      }

      em {
        position: absolute;
        top: 30px;
        right: 15px;
        font-style: normal;
      }

      &.mp-load-more {
        justify-content: center;
        display: flex;
        padding: 20px;
        font-size: 20px;
        align-items: center;
        color: $color-white;
        background-color: $color-doc-brand-sec;

        &:hover {
          background-color: darken($color-doc-brand-sec, 2.5%);
        }

        span {
          font-size: 20px;
          font-weight: 300;
          color: $color-white;
        }

        i {
          margin-right: 5px;
          font-size: 18px;
          color: $color-white;
        }
      }
    }
  }

  .chat-wrap {
    display: none; //Hide By Defualt//
    left: 420px;
    right: 0;
    width: auto;
    border: none;
    background: $color-messages-bg;

    @media only screen and (max-width: 1024px) {
      left: 320px;
    }

    @media only screen and (max-width: 880px) {
      left: 0;
      border-right: none;

      .header {
        text-overflow: ellipsis;
        white-space: nowrap;

        .modal {
          white-space: normal !important;
        }
      }
    }

    .chat-scroll {
      height: calc(100% - 80px);
    }

    .chat-action-list-btns {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;

      a {
        color: $color-white;
        background: $color-dark-grey;
        font-size: 0.7em;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        flex-shrink: 0;
        margin-right: 5px;
        border-radius: 4px;
        flex-shrink: 0;

        &:hover {
          text-transform: none;
        }

        &.green {
          background: $color-doc-brand-sec;
        }

        &.blue {
          background: $color-doc-brand;
        }

        &.red {
          background: $color-danger;
        }
      }

      &.aclb-mobile {
        display: none;

        @media only screen and (max-width: 880px) {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          padding-right: 15px;

          a {
            display: block;
            padding: 8px 10px;
            width: 90px;
            margin: 0 0 5px;
            line-height: 1;
            height: auto;
            text-align: center;
          }
        }
      }
    }

    .header {
      padding: 20px;

      .chat-wrap-toggle {
        display: none;
      }

      .this-chat-info {
        display: flex;
        align-content: center;
        align-items: center;

        @media only screen and (max-width: 880px) {
          flex-direction: column;
          align-content: flex-start;
          align-items: flex-start;
        }

        .tc-inner {
          display: flex;
          align-content: center;
          align-items: center;

          img {
            width: 32px;
            height: 32px;
            margin: 0 10px 0 0;
            border-radius: 50%;
            background: $color-mid-grey;
          }

          .tc-main {
            font-size: 0.65em;

            .tc-info {
              margin-bottom: 5px;

              span {
                font-weight: 400;
                color: $color-true-black;
              }

              b {
                margin: 0 5px;
              }
            }

            .tc-date {
              span {
                font-weight: 400;
              }
            }
          }
        }

        .tc-actions {
          align-self: flex-start;
          margin-left: 10px;
          display: flex;

          @media only screen and (max-width: 880px) {
            margin-left: 0;
            margin-top: 10px;
          }

          .label-wrap-left,
          .label-wrap-right {
            color: $color-white;
            background: $color-dark-grey;
            font-size: 0.7em;
            padding: 0 10px;
            height: 28px;
            line-height: 28px;
            flex-shrink: 0;
          }

          .label-wrap-left {
            cursor: pointer;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-right: 1px solid rgba(255, 255, 255, 0.22);
          }

          .label-wrap-right {
            display: flex;
            align-content: center;
            align-items: center;
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;

            label {
              position: relative;

              input {
                opacity: 0;
                margin: 0;
              }

              .radio-indicator {
                position: absolute;
                top: 9px;
                left: 0;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                border: 1px solid #fff;
                background: $color-grey;
                cursor: pointer;
              }

              input:checked ~ .radio-indicator {
                background: $color-doc-brand-sec;
              }
            }

            span {
              margin: 0 10px;
            }
          }
        }
      }

      .chat-action-list-wrap {
        margin-left: auto;
      }

      .chat-action-list {
        display: flex;

        @media only screen and (max-width: 880px) {
          display: none;
        }
      }

      .chat-action-list-ittem {
        position: relative;
        margin-left: 15px;

        @media only screen and (max-width: 620px) {
          position: initial;
        }

        .cpl-trigger {
          position: relative;
          display: flex;
          padding: 5px 0 2px 16px;
          align-content: center;
          align-items: center;
          cursor: pointer;
          border-left: 1px solid $color-grey;

          @media only screen and (max-width: 880px) {
            flex-direction: column;
            text-align: center;
            border: none;
          }

          &.no-border {
            border-left: none;
            padding-left: 0;
          }

          .cpl-tooltip {
            display: none;
            position: absolute;
            right: 0;
            top: 100%;
            min-width: 200px;
            margin-top: -4px;
            z-index: 2;
            background-color: $color-white;
            padding: 6px 15px;
            border-radius: $border-radius;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

            &:after {
              content: "";
              position: absolute;
              top: -12px;
              right: 12px;
              width: 0;
              height: 0;
              border-left: 12px solid transparent;
              border-right: 12px solid transparent;
              border-bottom: 12px solid $color-white;
            }

            .inner-pill {
              font-size: 0.925em;
              padding: 8px 0 2px;
              border-bottom: 1px solid rgba(0, 0, 0, 0.15);
              cursor: pointer;

              &:hover {
                color: $color-doc-brand-sec;

                i {
                  color: $color-doc-brand-sec;
                }
              }

              &:last-child {
                border: none;
              }

              i {
                margin-right: 5px;
                font-size: 15px;
                color: $color-true-black;
              }
            }

            button.inner-pill {
              display: block;
              width: 100%;
              text-align: left;
              background: none;
            }

            @media only screen and (max-width: 480px) {
              width: 100%;
              flex: 0 0 100%;
              position: relative;
              box-shadow: none;
              border-radius: 0;
              background: none;
              margin: 0;
            }
          }
        }

        i {
          display: inline-block;
          color: #1a3d78;
          line-height: 36px;
          text-align: center;
          font-size: 36px;
          margin-right: 10px;

          @media only screen and (max-width: 880px) {
            margin: 0 auto 2px;
          }
        }

        .cpl-options {
          cursor: pointer;
          background: none;
          padding: 0;

          i {
            color: #444;
          }
        }

        .btn-start-consult-mobile {
          z-index: 9;
          position: fixed;
          top: 0;
          right: 0;
          width: 80px;
          height: 80px;
          background-image: url(../../assets/images/start-consult-Icon.png);
          background-position: center center;
          background-size: 50px 40px;
          background-repeat: no-repeat;
        }

        .btn-begin {
          min-height: 24px;
          line-height: 24px;
          flex: 0 0 auto;
          display: inline-block;
          color: $color-white;
          text-align: center;
          font-size: 0.725em;
          margin-right: 12px;
          margin-bottom: 5px;
          background: $color-danger;
          text-decoration: none;

          &:hover {
            background: darken($color-danger, 5%);
          }
        }

        .pdf-info {
          cursor: pointer;
          padding-right: 15px;

          @media only screen and (max-width: 880px) {
            padding: 0;
            width: 100%;
          }

          b,
          span {
            display: block;
            font-size: 0.725em;
            line-height: 1.35;
          }

          b {
            color: $color-messages-text;
          }
        }

        .cpl-dropdown {
          position: absolute;
          top: 100%;
          right: 0;
          width: 220px;
          margin-top: 10px;
          padding: 20px 10px 50px;
          background: $color-white;
          @include border-top-radius($border-radius);
          @include border-bottom-radius($border-radius);
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

          ul {
            max-height: 200px;
            overflow: hidden;
            overflow-y: auto;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .cpl-btn {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: $color-doc-brand-sec;
            padding: 10px;
            cursor: pointer;
            @include border-bottom-radius($border-radius);
            color: $color-white;
            font-size: 14px;
            text-align: center;

            &:hover {
              background: darken($color-doc-brand-sec, 2%);
            }
          }

          @media only screen and (max-width: 620px) {
            width: calc(100% - 20px);
            margin: 0 10px;
          }
        }

        .cpl-pdf-item {
          position: relative;
          padding-right: 50px;
          display: flex;
          align-content: center;
          align-items: center;
          margin-left: auto;

          em {
            display: inline-block;
            width: 24px;
            height: 24px;
            border-radius: 3px;
            color: $color-white;
            line-height: 24px;
            text-align: center;
            font-size: 8px;
            font-style: normal;

            &.pdf {
              background: $color-file-pdf;
            }

            &.jpg {
              background: $color-file-jpg;
            }
          }

          .pdf-info {
            cursor: pointer;
            padding-left: 15px;
            padding-right: 0;

            b,
            span {
              display: block;
              font-size: 0.6em;
              line-height: 1.2;
              max-width: 110px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            b {
              color: $color-messages-text;
            }
          }
        }

        .cpl-delete {
          position: absolute;
          top: 50%;
          right: 0;
          padding: 0 10px;
          margin-top: -5px;
          font-size: 10px;
          color: $color-mid-grey;
        }

        .cpl-loading {
          opacity: 0.75;
        }

        .cpl-loader {
          position: absolute;
          top: 50%;
          right: 15px;
          margin-top: -10px;
          width: 20px;
          height: 20px;
        }
      }

      @media only screen and (max-width: 880px) {
        padding-left: 0;
        padding-right: 0;

        .header-upper {
          padding-right: 17.5px;
        }

        .chat-wrap-toggle {
          display: inline-block;
          height: 40px;
          padding: 0 10px;
          margin: 0 17.5px;
          background: $color-chat-panel;
          color: $color-doc-brand;
          text-align: center;
          text-transform: none;
          @include border-top-radius($border-radius);
          @include border-bottom-radius($border-radius);

          &:hover {
            text-decoration: none;
          }

          i {
            font-size: 20px;
            line-height: 40px;
            text-decoration: none;
          }
        }
      }
    }

    .chat-action-list-mobile {
      display: none;
      margin-left: auto;

      @media only screen and (max-width: 880px) {
        display: flex;
        align-content: center;
      }

      @media only screen and (max-width: 620px) {
        .cpl-trigger {
          position: initial !important;

          .cpl-tooltip {
            width: calc(100% - 20px) !important;
            margin: -15px 10px 0 !important;
            position: absolute !important;
            z-index: 999;
            background: #fff !important;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.2) !important;
            border-radius: 4px !important;

            &:after {
              right: 8px !important;
            }
          }
        }
      }
    }

    .chat-divider {
      width: auto;
      padding: 15px;
      margin: 60px 17.5px 30px;
      border-top: 2px solid $color-mid-grey;
      background: $color-chat-panel;
      @include border-top-radius($border-radius);
      @include border-bottom-radius($border-radius);
      text-align: center;
      font-size: 0.875em;
      color: $color-success;
      font-weight: 500;

      &:first-child {
        border: 0;
        margin-top: 0;
      }
    }
  }
}

/*
  Notes Panel
*/
.notes-pannel {
  position: absolute;
  z-index: 9999;
  right: 0;
  top: $top-bar-height;
  height: 100%;
  pointer-events: none;
  color: $color-true-black;

  .notes-pannel-inner {
    width: 0;
    height: 100%;
    background: $color-white;
    transition: all linear 0.25s;
    border-left: 1px solid $color-border-grey;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }

  &.active {
    pointer-events: all;

    .notes-pannel-inner {
      width: 420px;
    }
  }

  @media only screen and (max-width: 880px) {
    &.active {
      width: 100%;

      .notes-pannel-inner {
        width: 100%;
      }
    }
  }

  .np-header {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-border-grey;
    justify-content: space-between;

    a {
      text-decoration: none;
    }

    i {
      cursor: pointer;
      font-size: 24px;
    }
  }

  .np-actions {
    z-index: 2;
    position: absolute;
    top: 60px;
    width: 100%;
    height: 60px;
    padding: 10px 20px 0 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );

    .add-note {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
      color: $color-doc-brand-sec;

      i {
        margin-left: 5px;
      }
    }
  }

  .np-body {
    z-index: 1;
    padding: 60px 40px 40px;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100% - 80px);
    padding-bottom: 40px;

    .note-ittem-wrap {
      position: relative;
      margin-bottom: 40px;

      .note-ittem-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        padding: 8px 12px 0;
        align-items: center;

        h4 {
          font-size: 12px;
          margin-right: auto;
          color: #906310;
        }

        .delete-note {
          margin-left: 5px;
          font-size: 16px;
          color: #906310;
        }

        .saving-message {
          padding: 2px 5px;
          margin: 0;
          font-size: 10px;
          display: flex;
          align-items: center;

          i {
            margin-left: 3px;
            font-size: 10px;
          }
        }
      }

      .note-ittem {
        padding-top: 35px;
        background: linear-gradient($color-notes-a, $color-notes-b);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border: 0;
        border-radius: 3px;
        transition: box-shadow 0.5s ease;
        max-width: 360px;
        min-height: 220px;
        font-size: 12px;

        @media only screen and (max-width: 880px) {
          max-width: none;
        }

        &:hover {
          box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
        }

        &:focus {
          box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
          outline: none;
        }
      }
    }

    .note-ittem-loading {
      position: relative;
      padding: 10px;
      background: none;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 3px;
      transition: box-shadow 0.5s ease;
      max-width: 360px;
      min-height: 220px;
      font-size: 12px;

      @media only screen and (max-width: 880px) {
        max-width: none;
      }

      .loader-animation {
        width: 100%;
        height: 12px;
        margin-bottom: 8px;

        &:first-child,
        &:last-child {
          width: 50%;
        }
      }
    }
  }

  .np-body-files {
    padding: 50px 30px 30px;
    height: calc(100% - 120px);
  }

  .ad-upload-pack {
    margin: 0;

    ul {
      overflow: visible;
      max-height: none;

      li {
        width: 100%;
        padding: 10px;
        margin: 0 0 14px;
        background-color: $form-box;
        box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

//Appointment Chat
.messages-holder {
  &.active {
    .messages-list-pannel {
      width: 420px;
    }

    .chat-wrap {
      display: block;
    }

    @media only screen and (max-width: 1024px) {
      .messages-list-pannel {
        width: 320px;
      }
    }

    @media only screen and (max-width: 880px) {
      .messages-list-pannel {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 880px) {
    .chat-box-active {
      .appointment-chat-box-wrap {
        display: block;
      }
    }
  }

  &.stand-alone {
    .appointment-chat-box-wrap {
      left: 0px;
    }

    .messages-list-pannel {
      display: none;
    }

    .chat-wrap {
      display: block;
      left: 0;
    }

    @media only screen and (max-width: 880px) {
      .chat-wrap-toggle {
        display: none !important;
      }

      .this-chat-info {
        margin-left: 20px;
      }
    }
  }
}

.book-appointment-wrap {
  padding: 0 17.5px;
  color: $color-messages-text-mute;

  @media only screen and (max-width: 880px) {
    position: relative;
    padding-right: 100px;
  }

  h1 {
    font-size: 2.25em;
    color: $color-messages-text;
    margin-bottom: 35px;
  }

  p {
    margin-bottom: 40px;
  }

  .book-appointment-body {
  }

  .baw-info-title {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 880px) {
      flex-direction: column;
      position: relative;
      padding-right: 90px;
    }

    h1 {
      color: $color-true-black;
      font-size: 2.2em;

      span {
        font-weight: 300;
      }

      @media only screen and (max-width: 880px) {
        font-size: 1.2em;
        margin-bottom: 10px;

        span {
          display: block;
        }
      }
    }

    .baw-info-date {
      margin-left: 20px;

      @media only screen and (max-width: 880px) {
        margin-left: 0;
        margin-bottom: 40px;
      }

      strong,
      span {
        display: block;
        font-size: 1em;
        color: $color-true-black;
        line-height: 1.3;
      }
    }
  }

  .baw-info-body {
    display: flex;

    @media only screen and (max-width: 880px) {
      flex-direction: column;
    }
  }

  .baw-info-wrap {
    @media only screen and (max-width: 880px) {
      margin-bottom: 40px;
    }

    .info-block {
      display: inline-block;
      margin: 0 10px 5px 0;

      b {
        font-weight: 800;
      }
    }
  }

  .baw-actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    margin-left: auto;
    align-self: flex-start;

    @media only screen and (max-width: 880px) {
      margin-left: 0;
      flex-direction: row;
    }

    .btn {
      flex: 1;
      width: 130px;
      margin: 0 0 10px;
      padding: 8px 10px;
      border-radius: 4px;
      border-top-right-radius: 4px;
      font-size: 0.75em;
      font-weight: 400;
      cursor: pointer;
      color: $color-white;
      text-align: center;
      background-color: $color-doc-brand-sec;

      @media only screen and (max-width: 880px) {
        padding: 10px 5px;
        margin-right: 10px;
      }

      &:hover {
        background-color: darken($color-doc-brand-sec, 2%);
      }

      &.grey {
        background: $color-lighter-grey;
        color: $color-messages-text-mute;

        &:hover {
          background-color: darken($color-lighter-grey, 2%);
        }
      }

      &.dar-grey {
        background: $color-dark-grey;
        color: $color-white;

        &:hover {
          background-color: darken($color-dark-grey, 2%);
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .baw-dropdown {
      position: relative;
      display: inline-block;
      margin: 0 0 10px 0;

      @media only screen and (max-width: 880px) {
        margin-right: 10px;
      }

      .caret {
        position: absolute;
        top: 50%;
        right: 7px;
        width: 0;
        height: 0;
        margin-top: -3px;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 3px solid $color-dark-grey;
      }

      select {
        -webkit-appearance: none;
        width: 100%;
        width: 130px;
        padding: 8px 20px 8px 10px;
        border: none;
        background-color: $color-lighter-grey;
        cursor: pointer;
        color: $color-dark-grey;
        font-weight: 400;
        font-size: 0.75em;
        border-radius: 4px;
      }
    }
  }

  > .appointment-options-wrap {
    margin-left: -17.5px;
    margin-right: -17.5px;
  }
}

.appointment-options-wrap {
  margin: 0;
}

.appointment-chat-wrap,
.appointment-options-wrap {
  padding: 30px 60px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 -5px 10px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 768px) {
    padding: 30px 20px;
  }

  h2 {
    margin-bottom: 10px;
    color: #020202;
  }

  .aow-title {
    display: block;
    margin: 30px 0 10px;
  }

  .aow-row {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    padding-left: 35px;

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    i {
      position: absolute;
      top: 15px;
      left: 0;
      font-size: 20px;
      margin-right: 15px;
    }

    .multiline-form-box {
      margin-right: 15px;

      @media only screen and (max-width: 768px) {
        flex: 1;
        min-width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .multiline-form-box {
    width: 100%;
    max-width: 240px;

    select {
      width: 100%;
    }
  }

  .aow-row-chat-ittems {
    margin: 0;

    .arci-ittem {
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      .arci-time-stamp {
        font-size: 0.7em;
        margin-bottom: 10px;
        color: $color-mute-grey;
        font-weight: 400;

        .loader-animation {
          width: 80px;
          height: 8px;
        }
      }

      .arci-pic {
        width: 32px;
        height: 32px;
        flex: 0 0 32px;
        border-radius: 50%;
        margin: 3px 10px 3px 0;
        background-size: cover;
        background-position: center center;
      }

      .arci-message,
      > .loader-animation {
        flex: 1;
        padding: 10px 15px;
        margin-bottom: 10px;
        background: $form-box;
        border-radius: 6px;
        line-height: 1.2;
        font-weight: 400;
        color: $color-font-grey;

        &.loader-animation {
          width: 220px;
          height: 40px;
          padding: 0;
        }

        h4 {
          margin-bottom: 15px;
          font-weight: 600;
          font-size: 1em;
          border-bottom: 1px solid #dadada;
          padding-bottom: 5px;
        }

        a {
          cursor: pointer;
        }

        br {
          &:last-child {
            display: none;
          }
        }
      }

      .selection-row {
        display: flex;
        margin-top: 15px;
        align-items: center;
        align-content: center;
        font-size: 14px;

        &:first-child {
          margin-top: 20px;
        }

        span {
          margin-right: 25px;
        }

        .btn {
          margin-left: auto;
          min-width: 120px;
          padding: 3px 5px;
          border-radius: 3px;
          color: $color-white;
          text-align: center;
          background-color: $color-doc-brand-sec;
        }
      }

      img {
        width: 120px;
        border-radius: 6px;
        margin-right: 15px;

        &:last-child {
          margin: 0;
        }
      }

      &.received {
        align-items: flex-end;

        .arci-pic {
          margin: 3px 0 3px 10px;
        }

        .arci-message {
          max-width: 62%;
          background: $color-chat-bubble-sec;
          color: $color-white;

          @media only screen and (max-width: 480px) {
            max-width: 80%;
          }
        }
      }

      &.color-system {
        .arci-message {
          background: $color-chat-bubble-system;
        }
      }

      &.new-offer {
        .arci-message {
          border-top: 4px solid darken($color-chat-bubble-system, 15%);
          background: $color-chat-bubble-system;
          padding-bottom: 15px;
        }
      }

      &.system {
        flex-direction: row;
        justify-content: center;
        margin: 20px 0;

        .arci-message {
          max-width: 62%;
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1),
            0 -5px 10px rgba(0, 0, 0, 0.1);
          background: $color-white;
          color: $color-font-grey;
          padding: 0;

          @media only screen and (max-width: 768px) {
            max-width: 80%;
          }

          @media only screen and (max-width: 480px) {
            max-width: 100%;
          }

          .arci-message-body {
            padding: 10px 15px;
          }

          .arci-message-footer {
            background: rgba(0, 0, 0, 0.05);
            padding: 10px 15px;
            margin: 0;
            text-align: center;

            a {
              margin: 0 10px 0 0;
              color: $color-doc-brand-sec;
              font-size: 0.9em;
              font-weight: bold;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      &.initial-message {
        flex-direction: row;
        justify-content: flex-start;
        margin: 20px 0;

        .arci-message {
          max-width: 90%;
          background: rgba($color-chat-bubble-sec, 0.15);
          color: $color-chat-bubble-sec;
          border: 1px solid $color-chat-bubble-sec;
          padding: 0;
          flex: 1;
          max-width: none;

          .arci-message-body {
            padding: 10px 15px;

            h4 {
              border: none !important;
              margin-bottom: 5px;
            }

            p {
              font-size: 14px;

              &:not(:last-child) {
                margin-bottom: 3px;
              }
            }
          }
        }
      }

      &.time-stamp {
        .arci-time-stamp {
          margin: 10px auto;
        }
      }
    }
  }

  .aow-row-message {
    display: flex;

    input,
    textarea {
      flex: 1;
      display: block;
      max-width: 530px;
      background: $form-box;
      padding: 12px 15px 12px 15px;
      border: none;
      border-bottom: 2px solid $form-box-border;
      border-radius: 4px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: $form-box-text-dark;
      font-size: 1em;

      &:focus {
        border-bottom: 2px solid $color-doc-brand-sec;
        outline: none;
      }
    }

    textarea {
      height: 120px;
    }

    .btn {
      flex: 1;
      max-width: 120px;
      background: $form-box;
      padding: 12px 15px 12px 15px;
      border: none;
      border-radius: 4px;
      color: $form-box-text-dark;
      font-size: 0.9em;
      margin-left: 15px;

      &:hover {
        background: darken($form-box, 2%);
      }
    }

    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;

      input,
      .btn {
        flex: 1;
        min-width: 100%;
        margin: 0;
      }

      input {
        margin-bottom: 15px;
      }
    }
  }

  .aow-buttons-wrap {
    display: flex;

    .aow-buttons-group {
      display: flex;
      flex-direction: column;
      margin: 0 15px 0 0;

      @media only screen and (max-width: 768px) {
        padding: 10px 5px;
        min-height: 52px;
      }

      &:last-child {
        margin-right: 0;
      }

      label {
        font-size: 0.875em;
        font-weight: 400;
        margin-bottom: 5px;
      }

      .btn {
        flex: 1;
        max-width: 250px;
        padding: 15px 25px;
        font-weight: 400;
        border-radius: 4px;
        border-top-right-radius: 4px;
        cursor: pointer;
        color: $color-messages-text-mute;
        font-size: 0.875em;
        text-align: center;
        background-color: $color-lighter-grey;

        &:hover {
          background-color: darken($color-lighter-grey, 2%);
        }

        &.active {
          background-color: $color-dark-grey;
          color: $color-white;
        }
      }
    }
  }

  .aow-buttons-send,
  .aow-buttons-messsage-send {
    margin-top: 20px;

    .btn {
      flex: 1;
      width: 100%;
      max-width: 240px;
      padding: 15px 25px;
      font-weight: 400;
      border-radius: 4px;
      border-top-right-radius: 4px;
      cursor: pointer;
      color: $color-light-grey;
      font-size: 0.875em;
      text-align: center;
      background-color: $color-grey;

      &.active {
        background-color: $color-doc-brand-sec;
        color: $color-white;

        &:hover {
          background-color: darken($color-doc-brand-sec, 2%);
        }
      }
    }
  }

  .aow-buttons-send {
    padding-left: 35px;
  }
}

.appointment-chat-wrap {
  box-shadow: none;
  padding: 0 20px;

  .aow-row-chat-ittems {
    margin: 0 0 30px;

    .arci-ittem {
      margin-bottom: 10px;

      .arci-pic {
      }

      .arci-message {
        flex: 0 0 auto;
        padding: 10px 15px;
        margin-bottom: 5px;
        background: $form-box;
        border-radius: 6px;
        line-height: 1.2;
        font-weight: 400;
        color: $color-font-grey;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.appointment-chat-box-wrap {
  //display: none;
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 420px;
  padding: 0;
  margin: 20px;
  background: $color-white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 -5px 10px rgba(0, 0, 0, 0.1);
  @include border-top-radius($border-radius);
  @include border-bottom-radius($border-radius);

  @media only screen and (max-width: 1024px) {
    left: 320px;
  }

  @media only screen and (max-width: 880px) {
    left: 0;
  }

  textarea {
    background: none;
    outline: none;
    border: none;
    height: 38px;
    resize: none;
    font-size: 12px;
    padding: 10px;

    &:focus {
      outline: none;
      border: none;
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .acb-footer {
    display: flex;
    width: 100%;
    padding: 5px 10px 10px;
    justify-content: space-between;
    align-content: flex-end;
    align-items: flex-end;

    .acb-actions {
      a {
        font-size: 14px;
        margin: 5px;
        color: $color-dark-grey;

        &:hover {
          color: $color-doc-brand-sec;
          text-decoration: none;
        }
      }
    }

    .acb-submit {
      padding: 15px;
      min-width: 140px;
      text-align: center;

      background: $form-box;
      padding: 12px 15px 12px 15px;
      border-radius: 4px;
      color: $form-box-text-dark;
      font-size: 0.9em;

      &:hover {
        text-decoration: none;
        background: darken($form-box, 2%);
      }
    }

    .acb-icons {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-left: 8px;
      padding-bottom: 5px;

      i {
        display: inline-block;
        font-size: 12px;
        margin: 3px 5px;
        color: $color-grey;
        cursor: pointer;

        &:hover {
          color: darken($color-grey, 10%);
        }
      }
    }
  }
}

.modal-window {
  .chat-alert-modal {
    padding: 20px;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: 380px;
    border: none;
    border-radius: 8px;
    background: $color-white;
    color: $color-messages-box;

    .md-header {
      position: relative;
      height: 1px;
      padding: 0;

      .md-close {
        top: 0;
        right: 0;
        color: $color-font-grey;
        font-size: 22px;
      }
    }

    .md-body {
      padding: 0;
      text-align: center;

      i {
        display: inline-block;
        margin: 10px 0 18px;
        font-size: 40px;
        color: $color-danger;
      }

      p {
        line-height: 1.3;
        font-weight: 400;
      }
    }

    .md-footer {
      display: flex;

      .btn-large {
        flex: 1;
        text-align: center;
        margin: 5px;
      }
    }
  }
}

//Start Consult Button on main header
.start-consult-mobile {
  z-index: 9;
  position: fixed;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  background-image: url(../../assets/images/start-consult-Icon.png);
  background-position: center center;
  background-size: 50px 40px;
  background-repeat: no-repeat;
}
