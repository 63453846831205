.show-768 {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .landing-page {
    .site-header {
      position: relative;
    }

    .lndng-hero {
      margin-top: 0;
    }

    .press-hero {
      margin-top: 0;
    }
  }

  .find-wrap .find-block .find-block-footer {
    left: 0;
    right: 0;
    margin: 0 20px;
    width: auto;
  }
}

@media only screen and (min-width: 1025px) {
  .short-grid {
    max-width: 920px;
  }

  .landing-page {
    .sub--hero {
      margin-top: 130px;
    }

    .loading-login {
      height: 25px;
    }

    .home-primary-hero {
      h1 {
        font-size: 38px;
        max-width: none;
        letter-spacing: 2px;
      }

      h2 {
        font-size: 32px;
      }

      h3,
      .hidden--palm {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .landing-page {
    .lndng-section-mock {
      .flex-reverse {
        flex-direction: column-reverse;
      }

      img {
        margin-top: 40px;
      }

      ul {
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .landing-page .lndng-hero .home-hero .hero-badge {
    margin-right: -110px;
  }
}

@media screen and (max-width: 800px) {
  .landing-page .genesis-nav-menu > .menu-item.menu-item-popup {
    position: inherit;
  }

  .refer-wrap {
    padding: 40px 0;

    &.align-middle {
      align-items: top;
    }

    .refer-col {
      width: 100%;
      padding: 0 20px;

      &.top-form {
        padding: 0 20px;
      }

      img {
        margin-bottom: 50px;
      }

      &.border-right {
        border: none;

        .add-on {
          display: none;
        }
      }
    }
  }

  .ask-question {
    .home-label {
      position: relative;
      right: 0;
      top: 0;
      display: block;
      width: auto;
      margin-right: 0;
      line-height: 1;
      text-align: left;
    }
  }

  .landing-page {
    padding-top: 130px;

    .site-header {
      position: fixed;

      .wrap {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .login-link,
    .signup-link {
      padding: 15px;
      background: rgba(0, 0, 0, 0.9);
      border-bottom: 1px solid #444 !important;

      span {
        display: block;
        margin: 0;
        line-height: 30px;
      }
    }
  }

  //Responsive Menu//

  .landing-page {
    .responsive-menu-icon {
      display: block;
      margin: 0 !important;
      padding: 24px 15px !important;
    }

    .responsive-menu {
      position: fixed;
      top: 80px;
      left: 0;
      bottom: 0;
      width: 100%;
      display: none;
      float: none;
      padding: 0;
      margin: 0;
      overflow: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;

      .menu-item {
        display: block;
        padding: 0;
        margin: 0;

        &:hover {
          > a {
            border-bottom: 1px solid #444 !important;
          }
        }

        > a {
          border-bottom: 1px solid #444;
          line-height: 60px;
          background: rgba(0, 0, 0, 0.9);
          font-size: 16px;
          font-weight: 700;
          text-align: left;
          color: #fff !important;
          padding: 0 17.5px !important;
        }

        .sub-menu {
          width: auto;
          display: block;
          position: initial;
          opacity: 1;
          margin: 0;
          padding: 0;
          border: none;

          &:before,
          &:after {
            display: none;
          }

          a {
            position: relative;
            color: #fff !important;
            width: 100%;
            padding-left: 50px !important;
            border: none !important;
            border-bottom: 1px solid #444 !important;
            background: rgba(0, 0, 0, 0.9) !important;

            &:after {
              position: absolute;
              top: 0;
              left: 0;
              width: 50px;
              height: 60px;
              text-align: center;
              color: #fff;
              font-family: "icomoon" !important;
              speak: none;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 60px;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              content: "\e944";
            }
          }
        }

        &.menu-item-popup {
          position: initial;
          padding: 20px 10px;
          background: rgba(0, 0, 0, 0.9) !important;

          a {
            line-height: 40px;
          }
        }

        .popup-wrap.link a {
          line-height: 1;
          color: #fff;
          border: none;

          &:hover {
            color: #fff;
          }
        }
      }

      .popup-wrap {
        a {
          padding: 0;
        }
      }

      .side-right {
        display: block;
        width: 100%;

        li {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .hide-768 {
    display: none;
  }

  .show-768 {
    display: block;
  }

  .landing-page .screenshot-wrap {
    padding: 60px;
  }

  .find-wrap {
    .find-block {
      width: 50%;
      border-bottom: 2px solid rgba(0, 0, 0, 0.04);
    }
  }

  .browse--hospital {
    .browse__tabs {
      display: block;
      .tab {
        display: block;
        width: 100%;
      }
    }

    .spec-wrap .spec-block {
      width: 50%;
    }
  }

  .landing-page .screenshot-wrap .sw-img {
    margin-bottom: 40px;
    text-align: center;
  }

  .landing-page .screenshot-wrap .sw-text {
    text-align: center;
  }

  .landing-page .screenshot-wrap.inverse {
    flex-direction: column-reverse;
  }

  .landing-page .lndng-inner-counter.top,
  .landing-page .lndng-inner-counter.bottom {
    margin: 0;
    padding-top: 40px;
  }

  .landing-page .lndng-inner-content {
    min-height: 0;
    padding: 80px 20px 60px;

    h2 {
      font-size: 1.5em;
    }
  }

  .lndng-section-mock {
    h2 {
      font-size: 1.5em;
    }
  }

  .landing-page .lndng-inner-counter .count-wrap {
    margin-bottom: 10px;
  }

  .landing-page .lndng-section-trusted .companies-wrap a {
    max-height: 60px;
    max-width: 100px;
  }
}

@media screen and (max-width: 720px) {
  .landing-page .lndng-section-white .block-features li {
    width: 100%;
  }

  .landing-page .lndng-hero .home-hero {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  .landing-page .lndng-hero .home-hero .hero-badge {
    position: inherit;
    margin: 0 auto 20px;
    right: initial;
  }

  .landing-page .content-hero__body {
    padding: 0 20px;
  }

  .landing-page .consult-doctor-form {
    .btn-timer {
      font-size: 17px;
    }
  }
}

@media screen and (max-width: 620px) {
  .landing-page form.find-spec-form,
  .landing-page .consult-doctor-form {
    .form-col,
    p.col-08 {
      width: 100%;
    }
    .form-col-center {
      width: 229px;
      margin: 10px 0 10px 0;
    }
  }

  .find-wrap .find-block .btn-book {
    font-size: 0.8em;
  }

  .landing-page .consult-doctor-form .app-store-logo {
    width: 210px;
    height: auto;
  }

  .modal-window {
    .form-group {
      label,
      p strong,
      select,
      input,
      textarea,
      .select-control {
        width: 100%;
        text-align: left;
      }
    }

    .md-footer {
      width: 100%;
      max-width: none;
    }
  }

  .landing-page .lndng-pricing .row {
    display: block;

    .lp-block {
      margin-bottom: 20px;
    }
  }

  .landing-page .site-footer .nav-footer,
  .landing-page .site-footer .layout--social {
    display: block;
    margin-bottom: 10px;
  }
  .landing-page .invite-form {
    width: 95%;
    .inputs {
      flex-direction: column;
      input[type="text"] {
        width: 100%;
      }
    }

    .invite-other {
      text-align: left;
    }
    .submit-wrap {
      width: 100%;
    }
  }
  .landing-page .invite-more-colleagues {
    width: 280px;
  }

  .landing-page .lndng-hero .home-hero h1 {
    margin-top: 0;
  }

  .landing-page .screenshot-wrap {
    padding: 60px 30px;
  }
}

@media screen and (max-width: 620px) {
  .column-footer {
    .col-xs-fifth {
      width: 33.333%;
    }
  }
}

@media screen and (max-width: 480px) {
  .landing-page .lndng-hero {
    min-height: 0;
  }

  .landing-page .hero--cover {
    max-height: none;
  }

  .landing-page .hero.flex-embed .flex-embed__content {
    padding: 0;

    h1 {
      margin-bottom: 0;
    }
  }

  .landing-page .wrap {
  }

  .landing-page .lndng-hero .home-hero .home-hero-actions {
    display: block;

    .btn {
      display: block;
      width: auto;
      margin: 0 0 10px;
    }
  }

  .landing-page .side-panel {
    width: 100%;
    height: 100%;
    z-index: 9999;
    border: none;

    &.minimized {
      width: 100%;
      border-top: 2px solid #dddddd;
    }
  }

  .landing-page .side-panel .chat-box textarea {
    width: 100%;
    padding-right: 60px;
  }

  .side-panel .chat-box button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .browse--hospital {
    .spec-wrap .spec-block {
      width: 100%;
    }
  }

  .find-wrap {
    .find-block {
      width: 100%;
    }
  }

  .landing-page form.find-spec-form,
  .landing-page .consult-doctor-form {
    margin: 0;
  }

  .landing-page .site-header .responsive-menu-icon {
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  .landing-page {
    .lndng-section-mock {
      ul {
        text-align: left;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .column-footer {
    .col-xs-fifth {
      width: 50%;
    }
  }

  .register-block-wrap .rb-item {
    padding: 20px;
  }
}
