.business-page {
  .business-hero {
    margin-top: 130px;
    width: 100%;
    min-height: 320px;
    background: url(../../assets/images/business/business-hero.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    text-align: center;

    h2 {
      margin-top: 130px;
      font-size: 45px;
      line-height: 1.3;
      color: $color-white;
    }

    select {
      -webkit-appearance: none;
      outline: none;
      margin: 40px auto;

      height: 40px;
      width: 270px;
      padding: 0 20px;

      border: 2px solid #c3cace;
      background-image: url("../../assets/images/caret-white-down.png");
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 28px 12px;
      background-color: transparent;
      font-weight: 400;
      font-size: 16px;
      text-transform: uppercase;
      color: $color-white;
      border-radius: 0;
      text-align: center;
    }

    .form-container {
      padding: 60px 20px;

      .home-primary-form {
        h2 {
          text-align: center;
        }
      }

      h2 {
        margin: 0;
        text-align: left;
        font-weight: 400;
      }
    }
  }

  .lndng-section-white,
  .lndng-section-grey {
    padding: 80px 0;
  }

  h1.lined,
  h4.lined {
    text-align: center;
    font-size: 16px;
    color: $color-doc-brand;
    text-transform: uppercase;
    font-weight: 400;

    &:after {
      content: " ";
      display: block;
      width: 35px;
      height: 1px;
      background: $color-doc-brand;
      margin: 20px auto;
    }
  }

  p.large {
    font-weight: 400;
    font-size: 24px;
    line-height: 1.3;
    color: #5d5d5d;
    margin-bottom: 18px;
    margin-top: 10px;
  }

  p.small {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.3;
    color: #5d5d5d;
    margin-bottom: 12px;
  }

  p.small-list {
    font-weight: 300;
    font-size: 13px;
    line-height: 1.3;
    color: #5d5d5d;
    margin-bottom: 0;
  }
  a.btn-more {
    display: block;
    width: 150px;
    height: 40px;
    margin: 40px auto 0;
    padding: 0;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    background: $color-doc-brand;

    color: $color-white;
    border-radius: 5px;

    &:hover {
      background: darken($color-doc-brand, 7.5%);
    }
  }

  .company-partners {
    max-width: 1000px;
    margin: 40px auto 0;
    padding: 0 20px;

    img {
      width: 100%;
    }
  }

  .thumb-wrap {
    a.business-thumb {
      position: relative;
      display: block;
      height: 100%;
      margin: 15px 0;

      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: #fff;
        font-weight: 400;
        text-align: center;
        font-size: 22px;
        line-height: 1.2;
      }

      img {
        width: 100%;
      }

      //&:hover {
      //img {
      //opacity: 0;
      //}
      //s}
    }

    &.workplace {
      a.business-thumb {
        background: #14bdc4;
      }
    }

    &.industry {
      a.business-thumb {
        background: #00183e;
      }
    }
  }

  .show-extra-info {
    margin: 65px 0;

    p {
      text-align: justify;
    }

    .btn-more {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .body-section-hero {
    width: 100%;
    min-height: 520px;
    padding: 160px 0 50px;
    background: url(../../assets/images/business/body-hero.jpg);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    text-align: center;

    h2,
    p {
      margin: 0 30px;
    }

    h2 {
      color: $color-white;
      text-align: center;
      font-size: 46px;
      border-bottom: 1px solid $color-white;
      padding: 0 30px 10px;
      margin-bottom: 50px;
    }

    p {
      text-align: left;
      color: $color-white;
      font-size: 18px;
      line-height: 1.3;
      margin-bottom: 15px;
      padding: 0 10px;
    }

    .ceo-sign {
      margin: 50px 0 0;
      text-align: right;
      padding: 0 10px;
      font-weight: 400;

      p {
        display: inline-block;
        text-align: center;
        margin: 0;
      }
      span {
        display: block;
        color: $color-white;
        font-size: 12px;
      }

      .large {
        font-size: 26px;
      }
    }

    .demo-request {
      max-width: 270px;
      background: $color-white;
      border-radius: 6px;
      padding: 30px 20px;
      margin: 0 auto -100px;
      color: #3b3b3b;
      box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.3);

      h4 {
        color: inherit;
        font-weight: 400;
        margin-bottom: 25px;
      }

      span {
        position: relative;
        display: block;
        text-align: left;
        font-size: 12px;
        font-weight: 300;
        margin-bottom: 15px;
        color: #888888;
        padding-left: 15px;

        i {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 10px;
          line-height: 12px;
        }
      }

      .btn {
        display: block;
        height: 40px;
        line-height: 38px;
        padding: 0 15px;
        margin-top: 30px;
        background: #cc0d0d;
        border-color: #cc0d0d;
        border-radius: 6px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .site-header {
      .title-area {
      }
    }

    .business-hero {
      margin-top: 50px;
    }
  }

  @media only screen and (max-width: 992px) {
    .body-section-hero {
      .demo-request {
        margin-bottom: 0;
      }

      .for-business {
        margin-top: 80px;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    .business-hero {
      h2 {
        margin-top: 60px;
      }
    }

    .site-header {
    }
  }
}
