select.form-control {
  background-color: #fff;
  border-radius: 0;
  border: 1px solid #edeae2;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6666667;
  margin-bottom: 15px;
  padding-bottom: 14px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  -webkit-transition: border-color;
  transition: border-color;
  border-radius: 0;
  height: 60px;
  width: 100%;
}
