.rdt {
  font-size: 14px;
}

.rdtOpen {
  .rdtPicker {
    z-index: 4 !important;
    border-radius: $border-radius;
    margin-bottom: 20px;

    th {
      line-height: 28px;
      padding-bottom: 2px;
    }
  }
}

.rdtCounter {
  .rdtCount {
    height: auto;
    line-height: 28px;
  }
}

td.rdtMonth,
td.rdtYear {
  height: auto;
  line-height: 28px;
}

.rdtPicker td {
  padding: 10px;
}
