//Main

body {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $color-main-bg;
}
.precalltest {
  position: absolute;
  top: 0px;
  right: 0px;
}

.precalltest,
.precalltest .OT_subscriber,
.precalltest .OT_publisher {
  overflow: hidden;
}

.precalltest .OT_subscriber,
.precalltest .OT_publisher {
  float: left;
  width: 2px !important;
  height: 2px !important;
  min-width: 2px !important;
  max-width: 2px !important;
  min-height: 2px !important;
  max-height: 2px !important;
}

* {
  box-sizing: border-box;
}

small,
.small {
  font-size: 80%;
}

.hide {
  display: none !important;
}

.admin {
  height: 100%;
  overflow: auto;
}

.OT_publisher {
  width: 100px;
  height: 100px;
}
@media screen and (max-width: 650px) {
  .OT_publisher {
    width: 89px;
    height: 50px;
  }
}
