//Icons
$icon-microphone-off: "\e8cc";
$icon-microphone: "\e8cb";
$icon-camera-off: "\e700";
$icon-camera: "\e6ff";
$icon-cross: "\e935";
$icon-question: "\e933";
$icon-chat-tip-right: "\e9ee";
$icon-chat-tip-left: "\e9ed";
$icon-check: "\e934";
$icon-search: "\e922";
$icon-mute: "\e8b4";
$icon-volume-high: "\e8b0";
$icon-stethoscope: "\e9ea";
$icon-plus: "\e936";
$icon-paperclip: "\e698";

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.icon-spin {
  -webkit-animation: icon-spin 1.75s infinite linear;
  animation: icon-spin 1.75s infinite linear;
}
.icon-green {
  color: $color-mz-green;
}
.icon-blue {
  color: $color-mz-blue;
}
.icon-red {
  color: $color-mz-red;
}
.icon-yellow {
  color: $color-mz-yellow;
}
