.appointment-steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  margin: 15px 0 15px;

  &:after {
    position: absolute;
    top: -10px;
    left: 65px;
    right: 65px;
    content: "";
    height: 2px;
    background: $color-doc-brand-sec;
  }

  .step-count {
    z-index: 2;
    position: relative;
    margin-top: 15px;
    font-size: 0.75em;
    text-align: center;
    line-height: 1.4;
    width: 90px;

    &:after {
      position: absolute;
      top: -40px;
      left: 50%;
      margin-left: -15px;
      content: attr(data-line);
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: $color-white;
      text-align: center;
      line-height: 30px;
      font-size: 14px;
      font-weight: 700;
    }

    &.step-active {
      &:after {
        color: $color-white;
        background: $color-doc-brand-sec;
      }
    }
  }
}

.appointment-details {
  background: rgba($color-doc-brand, 0.06);
  padding: 10px 15px;
  border-radius: 6px;
  margin-bottom: 20px;

  p {
    margin: 0px;
    line-height: 1.5;
    line-height: 1.5;

    .info-icon {
      vertical-align: middle;
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: $color-dark-grey;
      color: $color-white;
      line-height: 16px;
      text-align: center;
      font-style: normal;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .small {
    font-size: 80%;
  }

  a {
    color: $color-dark-grey;
    text-decoration: underline;
  }

  .info-wrap {
    position: relative;
    padding: 10px;
    margin-top: 10px;
    border-radius: $border-radius;
    background: rgba($color-info, 0.1);

    .close {
      position: absolute;
      top: 4px;
      right: 4px;
      color: $color-info;
      text-decoration: none;

      i {
        font-size: 14px;
      }
    }

    p {
      font-size: 0.75em;
      font-weight: 500;
      color: $color-info;
      em {
        display: block;
        padding-left: 8px;
      }
    }
  }
}

.appointement-referal {
  background: rgba($color-doc-brand, 0.06);
  padding: 10px 0;
  border-radius: 6px;
  margin-bottom: 20px;
  display: flex;

  input {
    background: rgba($color-white, 0.5) !important;
  }

  .col-block {
    padding: 0 15px;
    width: 50%;
    border-left: 1px solid #ccc;

    &:first-child {
      border: none;
    }

    p {
      small {
        font-size: 80%;
      }
    }
  }
}

.final-details {
  padding-top: 15px;

  h4 {
    font-size: 1em;
    font-weight: 500;
    margin: 15px 0 0;
  }

  p {
    display: flex;
    font-size: 80%;
  }

  em {
    padding-left: 15px;
  }
}

.appointments-inner {
  margin-top: 60px;
  padding: 20px;
}

.appointments-dash {
  position: relative;
  width: auto;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 6px;
  background: $color-white;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
  color: $color-true-black;
}

.appointments-dash-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 1em;
    font-weight: 800;
    text-transform: uppercase;
  }

  .back-btn {
    color: $color-dark-grey;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.8em;
  }

  .add-btn {
    cursor: pointer;
    font-size: 0.9em;
    padding: 10px 20px;
    border-radius: 4px;
    color: $color-white;
    background-color: $color-doc-brand-sec;
    text-transform: uppercase;
  }
}

.appointments-dash-title {
  font-size: 0.8em;
  font-weight: 800;
  text-transform: capitalize;
}

.admin-viewmore-toggle {
  margin-left: auto;
}

.appointments-dash-body {
  .ad-pill {
    position: relative;
    background: $color-white;
    border-radius: 4px;
    border-left: 3px solid $color-grey;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
    margin: 10px 0;
    display: flex;

    .ad-pill-info {
      flex: 1;
      padding: 10px 15px;
      border-top: 1px solid $color-blueish-grey;
      border-bottom: 1px solid $color-blueish-grey;
      width: calc(100% - 200px);

      span {
        display: block;
        line-height: 1.25;
        font-weight: 400;
        white-space: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .loader-animation {
        height: 22px;
        padding: 5px 0;
      }
    }

    .ad-pill-chat {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 70px;
      background: $color-doc-brand-sec;
      text-align: center;

      i {
        margin: 0 auto;
        color: $color-white;
        font-size: 28px;
      }

      &.apc-past {
        background: $color-grey;
      }
    }

    .ad-pill-status {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: $color-grey;
      width: 70px;
      text-align: center;
      justify-content: center;

      span {
        display: inline-block;
        color: $color-white;
        text-transform: uppercase;
        font-size: 0.75em;
        line-height: 1.15;
      }
    }

    .ad-pill-options {
      cursor: pointer;
      display: flex;
      align-items: center;
      background-color: $color-dark-grey;
      width: 50px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      text-align: center;
      justify-content: center;
      flex-shrink: 0;

      &:hover {
        background-color: darken($color-dark-grey, 5%);
      }

      i {
        font-size: 30px;
        color: $color-white;
      }
    }

    &.status-danger {
      border-left: 3px solid $color-danger;

      .ad-pill-status {
        background: $color-danger;
      }
    }

    &.status-success {
      border-left: 3px solid $color-success;

      .ad-pill-status {
        background: $color-success;
      }
    }

    &.status-past {
      border-left: 3px solid $color-grey;

      .ad-pill-chat {
        background: $color-grey;
      }

      .ad-pill-status {
        background: $color-doc-brand-sec;
      }
    }

    &.status-info {
      border: 1px solid $color-chat-blue;
      background: rgba(0, 132, 255, 0.07);

      .ad-pill-info {
        border: none;
        background: none;

        span {
          font-size: 0.8em;
          font-weight: 500;
          line-height: 1.4;
          color: $color-chat-blue;

          a {
            color: $color-chat-blue;
            text-decoration: underline;
          }
        }
      }

      .ad-pill-chat {
        border: none;
        background: $color-grey;
      }

      .ad-pill-status {
        border: none;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        background: $color-doc-brand-sec;
      }
    }

    &.options-active {
      .options-inner {
        display: flex;
      }

      .ad-pill-status {
        display: none;
      }
    }

    .options-inner {
      display: none;

      .inner-pill {
        width: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        background-color: $color-dark-grey;
        text-align: center;
        justify-content: center;
        border-radius: 0;
        border: none;
        flex-shrink: 0;

        &.ip-red {
          background-color: $color-danger;
        }

        &.ip-blue {
          background-color: $color-info;
        }

        &.ip-green {
          background-color: $color-doc-brand-sec;
        }

        i {
          font-size: 20px;
          color: $color-white;
        }
      }
    }

    .i-open {
      display: none;
    }

    &.options-active {
      .ad-pill-chat {
        display: none;
      }

      .i-close {
        display: none;
      }

      .i-open {
        display: block;
        font-size: 24px;
      }
    }

    .options-inner-modal {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      margin-top: 3px;
      z-index: 2;
      background-color: $color-white;
      padding: 6px 15px;
      border-radius: $border-radius;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      &:after {
        content: "";
        position: absolute;
        top: -12px;
        right: 14px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid $color-white;
      }

      .inner-pill {
        font-size: 0.925em;
        padding: 12px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        cursor: pointer;

        &:hover {
          color: $color-doc-brand-sec;
        }

        &:last-child {
          border: none;
        }

        i {
          margin-right: 5px;
          font-size: 15px;
        }
      }

      button.inner-pill {
        display: block;
        width: 100%;
        text-align: left;
        background: none;
      }

      @media only screen and (max-width: 480px) {
        width: 100%;
        flex: 0 0 100%;
        position: relative;
        box-shadow: none;
        border-radius: 0;
        background: none;
        margin: 0;
      }
    }

    &.options-modal-active {
      @media only screen and (max-width: 480px) {
        flex-wrap: wrap;

        .ad-pill-options {
          border-bottom-right-radius: 0;
        }
      }

      .options-inner-modal {
        display: block;
      }
    }
  }

  .ad-pill-button {
    border: 1px solid $color-blueish-grey;
  }

  .ad-pill-input {
    border: 1px solid $color-blueish-grey;
    border-left: 3px solid $color-danger;

    &.referal-pill {
      border-left: 3px solid $color-doc-brand;
    }

    &.ad-pill-button {
      border-left: 1px solid $color-blueish-grey;
    }

    .rdt-span {
      width: 100% !important;
      flex: auto !important;

      span {
        display: block;

        .loader-animation {
          height: 12px;
        }
      }
    }

    button,
    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      background: $color-doc-brand-sec;
      border: none;
      border-radius: 3px;
      outline: none;
      font-size: 0.875em;
      text-align: center;
      color: $color-white;
      cursor: pointer;

      &:hover {
        background: darken($color-doc-brand-sec, 2.5%);
      }

      i {
        font-size: 24px;
        margin-right: 8px;
      }
    }

    input,
    textarea,
    select,
    .read-only {
      height: auto;
      background: none;
      border: none;
      outline: none;
      font-size: 0.875em;
    }

    input {
      height: auto;
    }

    textarea {
      resize: none;
      height: 130px;
    }

    select {
      -webkit-appearance: none;
      width: 100%;
      height: 50px;
      padding: 0 15px;
      background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3C!-- Generator: Adobe Illustrator 24.1.2, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' focusable='false' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0{fill:%23747474;} %3C/style%3E%3Cpath class='st0' d='M4.5,7.5c0.4-0.4,1-0.5,1.6,0l3.9,3.7l3.9-3.7c0.5-0.5,1.1-0.4,1.6,0c0.4,0.4,0.4,1.2,0,1.6 c-0.4,0.4-4.7,4.5-4.7,4.5C10.6,13.9,10.3,14,10,14s-0.6-0.1-0.8-0.3c0,0-4.3-4.1-4.7-4.5S4.1,8,4.5,7.5z'/%3E%3C/svg%3E");
      background-size: 20px;
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) center;
    }

    .read-only {
      height: 50px;
      padding: 15px;
      display: flex;
      align-items: center;
      color: $color-true-black;

      i {
        margin-right: 5px;
      }
    }

    .react-phone-number-input {
      width: 100%;
      padding: 0 14px;
      font-size: 15px;

      input {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .rdt {
      font-size: 15px;

      &:last-child {
        border-left: 1px solid $color-blueish-grey;
      }
    }

    .rdt-span {
      flex: 1;
      display: flex;
      min-height: 50px;
      padding: 0 15px;
      align-content: center;
      align-items: center;

      span {
        font-size: 0.875em;
        color: $color-true-black;
        font-weight: 400;
      }

      &.rdt {
        padding: 0;
      }

      &:first-child {
        span {
          text-align: left !important;
        }
      }

      &:last-child {
        width: 120px;
        flex: 0 0 120px;

        span {
          display: block;
          width: 100%;
          text-align: right;
        }
      }
    }

    .checkbox-label {
      display: flex;
      align-items: center;
      padding: 16px 10px;

      span {
        margin-left: 5px;
        font-weight: 400;
        color: #000;
        font-size: 0.9em;
      }
    }
  }

  .ad-pill-group {
    .ad-pill {
      margin-top: 0;
      margin-bottom: 0;
      border-bottom-width: 0;
      border-radius: 0;

      &:first-child {
        margin-top: 10px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &:last-child {
        margin-bottom: 10px;
        border-bottom-width: 1px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

.ad-upload-pack {
  width: 100%;
  margin-top: 10px;

  ul {
    max-height: 100px;
    overflow: hidden;
    overflow-y: auto;
    margin: 0;
    padding: 0 5px 0 0;
    list-style: none;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .cpl-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: $color-doc-brand-sec;
    padding: 10px;
    cursor: pointer;
    @include border-bottom-radius($border-radius);
    color: $color-white;
    font-size: 14px;
    text-align: center;

    &:hover {
      background: darken($color-doc-brand-sec, 2%);
    }
  }

  @media only screen and (max-width: 620px) {
    width: 100%;
  }

  .cpl-pdf-item {
    position: relative;
    padding-right: 50px;
    display: flex;
    align-content: center;
    align-items: center;
    margin-left: auto;

    em {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 3px;
      color: $color-white;
      line-height: 24px;
      text-align: center;
      font-size: 8px;
      font-style: normal;

      &.pdf {
        background: $color-file-pdf;
      }

      &.jpg {
        background: $color-file-jpg;
      }
    }

    .pdf-info {
      cursor: pointer;
      padding-left: 15px;
      padding-right: 0;

      b,
      span {
        display: block;
        font-size: 0.6em;
        line-height: 1.2;
        max-width: 110px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      b {
        color: $color-messages-text;
      }
    }
  }

  .cpl-delete {
    position: absolute;
    top: 50%;
    right: 0;
    padding: 0 10px;
    margin-top: -5px;
    font-size: 10px;
    color: $color-mid-grey;
  }

  .cpl-ready {
    color: $color-doc-brand-sec;
    margin-left: auto;
  }

  .cpl-loading {
    opacity: 0.75;
  }

  .cpl-loader {
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -10px;
    width: 20px;
    height: 20px;
  }
}

.appointments-dash-footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  a {
    cursor: pointer;
    text-decoration: underline;
    color: $color-dark-grey;
    font-size: 0.725em;
    font-weight: 500;
  }

  .ad-pag {
    a,
    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      display: inline-block;
      font-size: 0.8em;
      font-weight: 400;
      border-radius: 3px;
      text-decoration: none;
    }

    a {
      border: 1px solid $color-mid-grey;
      color: $color-mid-grey;

      i {
        font-size: 10px;
        color: $color-dark-grey;
      }

      &:hover {
        color: $color-white;
        background: $color-mid-grey;
        border: 1px solid $color-mid-grey;

        i {
          color: $color-white;
        }
      }

      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }

    span {
      color: $color-white;
      background: $color-mid-grey;
      border: 1px solid $color-mid-grey;
    }

    @media only screen and (max-width: 768px) {
      a,
      span {
        margin: 0 1px;
        width: 22px;
        height: 22px;
        line-height: 22px;
      }
    }
  }
}

.md-appointment {
  .modal {
    padding: 20px;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: 380px;
    border: none;
    border-radius: 8px;
    background: $color-white;
    color: $color-font-grey;

    .modal-footer {
      display: block;
      height: 50px;
      margin: 20px -20px -20px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      background: $color-doc-brand-sec;
      color: $color-white;
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.1em;
      font-weight: 800;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
        background: darken($color-doc-brand-sec, 2.5%);
      }

      &.disabled {
        background: $color-dark-grey;
        cursor: not-allowed;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .appointments-dash-title {
    margin-top: 35px;
  }
}
