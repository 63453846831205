$bar-height: 50px;
$color-one: rgba(242, 77, 93, 1);
$color-two: rgba(161, 5, 29, 1);
$color-text: $color-white;

.landing-page {
  .site-header {
    margin-top: $bar-height;

    &.header-hide-banner {
      margin-top: 0;

      .header-contact-wrap {
        display: none;
      }
    }

    .header-contact-wrap {
      position: absolute;
      top: calc(#{$bar-height} * -1);
      background: $color-one;
      background: linear-gradient(180deg, $color-one 0%, $color-two 100%);
      height: $bar-height;
      width: 100%;

      line-height: $bar-height;
      text-align: center;
      color: $color-text;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        background: darken($color-one, 5%);
        background: linear-gradient(
          180deg,
          darken($color-one, 5%) 0%,
          darken($color-two, 5%) 100%
        );
      }
    }
  }

  @media screen and (max-width: 800px) {
    .responsive-menu {
      top: calc(80px + #{$bar-height});
    }
  }

  .lndng-hero {
    margin-top: calc(80px + #{$bar-height});

    @media screen and (max-width: 1024px) {
      margin-top: 0;
    }

    @media screen and (max-width: 768px) {
      margin-top: $bar-height;
    }
  }
}
