.bot-overlay,
.bot-inline {
  z-index: 9991;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-doc-brand-sec;
  color: $color-white;
  font-weight: 300;

  &.bot-inline {
    position: relative;
    width: 100%;
    height: 100vh;
    min-height: 400px;
    margin-top: -30px;
  }

  .bot-close {
    z-index: 1000;
    display: block;
    color: $color-white;
    position: absolute;
    top: 0;
    right: 0;
    margin: 30px;
    cursor: pointer;

    i {
      font-size: 32px;
    }
  }

  .bot-login-btn {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;

    .btn {
      display: inline-block;
      margin: 20px;
      background-color: $color-white;
      border: none;
      color: $color-doc-brand-sec;
      padding: 0px 20px;
      line-height: 36px;
      border-radius: 3px;

      &:hover {
        background-color: darken($color-white, 7.5%);
        color: $color-doc-brand-sec;
      }
    }
  }

  .bot-header {
    z-index: 1000;
    color: $color-white;
    position: absolute;
    top: 0;
    right: 50%;
    width: 220px;
    padding: 20px;
    margin-right: -110px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .bot-send-form {
    width: 100%;
    max-width: 540px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s ease;
    padding: 0 20px;

    &.stick-bottom {
      margin-top: auto;
      height: 100px;
      transition: all 0.5s ease;
    }

    input {
      color: $color-white;
      padding: 8px;
      background: none;
      outline: none;
      border: none;
      border-bottom: 1px solid $color-white;
      line-height: 1;
      font-size: 1.5em;
      font-weight: 400;
      margin-bottom: 0;

      &:focus {
        box-shadow: none;
        outline: none;
        border: none;
        border-bottom: 1px solid $color-white;
      }
    }

    button {
      display: block;
      height: 45px;
      margin-left: auto;
      background: none;
      border: none;
      outline: none;
      color: $color-white;
      font-weight: 500;
      font-size: 1.6em;
      padding: 0 4px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .bot-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-direction: column;

    .upload-file-selector {
      display: none;
    }

    h4 {
      color: $color-white;
      font-size: 1.75em;
      font-weight: 200;
      margin-bottom: 25px;
      max-width: 75%;

      i {
        display: block;
        font-size: 1.5em;
        margin-right: 5px;
        margin-bottom: 20px;
        text-shadow: none;
        text-align: center;
      }
    }
  }
  .bot-chat-initial {
    margin: auto 0;
  }

  .bot-chat {
    margin: auto 0 0;
  }
  .bot-chat,
  .bot-chat-initial {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 680px;
    height: calc(100vh - calc(100vh - 100%) - 100px);

    a {
      color: white;
    }

    &:before {
      display: none;
      z-index: 3;
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 80px;

      background: -moz-linear-gradient(
        top,
        rgba(19, 190, 196, 1) 0%,
        rgba(19, 190, 196, 0) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(19, 190, 196, 1) 0%,
        rgba(19, 190, 196, 0) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(19, 190, 196, 1) 0%,
        rgba(19, 190, 196, 0) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#13bec4', endColorstr='#0013bec4',GradientType=0 ); /* IE6-9 */
    }

    &:after {
      pointer-events: none;
      z-index: 3;
      content: "";
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 80px;
      background: -moz-linear-gradient(
        top,
        rgba(19, 190, 196, 0) 0%,
        rgba(19, 190, 196, 1) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        top,
        rgba(19, 190, 196, 0) 0%,
        rgba(19, 190, 196, 1) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to bottom,
        rgba(19, 190, 196, 0) 0%,
        rgba(19, 190, 196, 1) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0013bec4', endColorstr='#13bec4',GradientType=0 ); /* IE6-9 */
    }

    &-initial {
      flex-direction: column;
      height: auto;

      &:after {
        display: none;
      }
    }

    .bot-chat-scroll {
      width: 100%;
      height: 100vh;
      max-height: 100%;
      overflow: hidden;
      overflow-y: auto;
      padding: 60px 20px 40px;
    }

    .bc-bubble,
    .bc-bubble-sudgestions {
      flex: 0 0 auto;
      position: relative;
      width: 100%;
      padding: 0 70px;
      margin-bottom: 20px;

      .upload-file-selector {
        display: none !important;
      }

      i {
        position: absolute;
        top: 50%;
        margin-top: -20px;
        font-size: 42px;
        color: $color-white;
      }

      &.in {
        i {
          left: 0;

          &:before {
            content: "\ea09";
          }
        }
      }

      &.out {
        i {
          left: initial;
          right: 0;

          &:before {
            content: "\ea0a";
          }
        }
      }

      .bc-bubble-text {
        display: inline-block;
        border-radius: 8px;
        padding: 30px;
        width: 75%;
        line-height: 1.2;
        font-size: 1.55em;
        font-weight: 300;

        a {
          color: $color-white;
          font-weight: 400;
          text-transform: lowercase;
          font-size: inherit;
          text-decoration: underline;
        }
      }

      &.in {
        text-align: left;

        .bc-bubble-text {
          background: rgba(0, 0, 0, 0.15);
        }
      }

      &.out {
        text-align: right;

        .bc-bubble-text {
          background: rgba(255, 255, 255, 0.15);
          text-align: left;
        }
      }
    }

    .bc-bubble-sudgestions {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px;

      span {
        text-align: left;
        display: block;
        min-width: 100%;
        margin: 5px 5px 15px;
        font-size: 12px;
      }

      a {
        cursor: pointer;
        flex: 0 0 31.3%;
        margin: 0 1% 10px 1%;
        text-align: center;
        color: $color-white;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid $color-white;
        font-weight: 300;
      }

      .a-red {
        background-color: $color-danger;
        color: $color-white;
        border: none;
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .bot-overlay {
    .bot-container {
      h4 {
        padding: 0 20px;
        font-size: 1em;
        text-align: center;
      }
    }

    .bot-chat {
      height: calc(100vh - calc(100vh - 100%) - 100px);

      .bc-bubble {
        padding: 0 45px;

        i {
          font-size: 32px;
          margin-top: -16px;
        }

        .bc-bubble-text {
          width: 80%;
          font-size: 1em;
          padding: 15px;
        }
      }
    }
  }

  .bot-overlay .bot-chat-initial .bc-bubble-sudgestions {
    padding: 0 20px;
  }

  .bot-overlay .bot-chat .bc-bubble-sudgestions {
    padding: 0 40px;
  }

  .bot-inline .bot-chat,
  .bot-inline .bot-chat-initial {
    .bot-chat-scroll {
      padding: 10px 10px 40px;

      .bc-bubble {
        padding: 0 35px;

        i {
          font-size: 24px;
          margin-top: -12px;
        }

        .bc-bubble-text {
          width: 90%;
          padding: 15px;
          font-size: 1.2em;
        }
      }

      .bc-bubble-sudgestions {
        padding: 0 25px;
      }
    }
  }

  .dropdown-bot {
    width: 100%;

    button.dropdown-toggle {
      min-width: 0;

      .dropdown-menu {
        width: 100%;
        left: 0;
        margin: 0;
      }
    }
  }
}

.landing-page {
  .bot-inner-wrap {
    z-index: 1;
    position: relative;
    height: auto;
    min-height: calc(100vh - 130px);
    padding: 0;

    .bot-inline {
      position: absolute;
      z-index: 1;
      min-height: calc(100vh - 130px);
      height: 100%;
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 480px) {
  .bot-overlay {
    .bot-container {
      h4 {
        font-size: 1.6em;
      }
    }

    .bot-chat {
      &:before {
        display: block;
      }
    }

    .bot-chat {
      .bc-bubble {
        .bc-bubble-text {
          width: 100%;
        }
      }
    }

    .bot-chat,
    .bot-chat-initial {
      .bc-bubble-sudgestions,
      .bc-bubble-sudgestions {
        a {
          flex: 0 0 48%;
        }
      }
    }
  }
}

//Bot Drop Down

.bot-drop-wrap {
  position: relative;
  width: 100%;
  max-width: 540px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all 0.5s ease;
  padding: 0 20px;

  &.stick-bottom {
    height: 100px;
    margin-top: auto;
    margin-bottom: 220px;
    transition: all 0.5s ease;
  }

  .bot-drop-toggle {
    color: $color-white !important;
    padding: 8px !important;
    background: none !important;
    outline: none !important;
    border: none !important;
    border-bottom: 1px solid $color-white !important;
    line-height: 1 !important;
    font-size: 1.5em !important;
    font-weight: 400;
    margin-bottom: 0 !important;
    box-shadow: none !important;

    &:focus {
      box-shadow: none;
      outline: none;
      border: none;
      border-bottom: 1px solid $color-white;
    }
  }

  .bot-drop-send {
    display: block;
    height: 45px;
    margin-left: auto;
    background: none;
    border: none;
    outline: none;
    color: $color-white;
    font-weight: 500;
    font-size: 1.6em;
    padding: 0 4px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bot-drop-menu {
  position: absolute;
  bottom: 100%;
  left: 20px;
  z-index: $zindex-dropdown;
  width: calc(100% - 40px);
  padding: 0;
  margin: 0; // override default ul
  font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
  color: $body-color !important;
  text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: none;
  border-radius: 1px !important;
  box-shadow: $box-shadow !important;
  max-height: 102px;
  overflow: hidden;
  overflow-y: auto;

  div {
    display: block;
    cursor: pointer;
    width: 100%; // For `<button>`s
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    clear: both;
    font-weight: 500;
    font-size: 0.875em;
    color: $dropdown-link-color;
    text-align: inherit; // For `<button>`s
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    background: none; // For `<button>`s
    border: 0; // For `<button>`s
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:focus {
      color: $dropdown-link-hover-color;
      text-decoration: none;
      background-color: $dropdown-link-hover-bg;
    }

    &.selected,
    &:active {
      color: $dropdown-link-active-color;
      text-decoration: none;
      background-color: $dropdown-link-active-bg;
    }

    &.disabled,
    &:disabled {
      color: $dropdown-link-disabled-color;
      background-color: transparent;
      // Remove CSS gradients if they're enabled
    }
  }
}

//Maps autocomplite popup
.pac-container {
  top: initial !important;
  bottom: 100px !important;
  border-radius: 5px;
}
