textarea,
input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input:not([type]),
select[multiple="multiple"] {
  background-color: #fff;
  border-radius: 0;
  border: 1px solid rgba(22, 23, 56, 0.22);
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6666667;
  padding-bottom: 14px;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 14px;
  -webkit-transition: border-color;
  transition: border-color;
  width: 100%;

  &:disabled {
    background: $color-border-grey !important;
    border-color: transparent !important;
    cursor: not-allowed;
  }
}

.form-control-upload {
  position: relative;
  outline: none;
  border-radius: 3px;
  height: 40px;
  background-color: transparent;
  border: 1px solid rgba(35, 35, 93, 0.15);
  padding: 0;
  background-color: transparent;

  input[type="file"],
  input[type="file"]:focus,
  input[type="file"]:active,
  .multiple-message {
    z-index: 2;
    position: absolute;
    top: 7.5px;
    left: 0;
    border: none;
    height: auto;
    width: calc(100% - 35px);
    margin: 0 30px 0 5px;
    padding: 0;
  }

  &.multiple {
    input[type="file"],
    input[type="file"]:focus,
    input[type="file"]:active {
      opacity: 0;
    }

    .multiple-message {
      top: 0;
      left: 90px;
      width: auto;
      margin: 0;
      line-height: 35px;
      font-size: 12px;
      pointer-events: none;
    }
  }

  label.trigger {
    display: block;
    position: absolute;
    z-index: 2;
    top: 2px;
    left: 2px;
    bottom: 2px;
    width: 80px !important;
    margin: 0;
    background: $color-mute-grey;
    color: $color-white;
    text-align: center !important;
    line-height: 30px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 0.875em;

    &:hover {
      background: darken($color-mute-grey, 10%);
    }
  }

  a {
    position: absolute;
    top: 0;
    right: 0;
    color: $color-darker-grey !important;

    i {
      display: block;
      width: 30px;
      height: 40px;
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.multiple-attachment {
  overflow: hidden;
  overflow-y: auto;
  max-height: 100px;
  margin-bottom: 6px;
}

.multiple-attachment-link,
.multiple-attachment-load {
  position: relative;
  display: block;
  width: 100%;
  height: 35px;
  margin-bottom: 6px;
  background-color: transparent;
  border: 1px solid rgba(35, 35, 93, 0.15);
  border-radius: 3px;

  i {
    position: absolute;
    top: 0;
    right: 0;
    color: $color-darker-grey !important;
    display: block;
    width: 30px;
    height: 34px;
    font-size: 15px;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
  }
}

.multiple-attachment-link a,
.multiple-attachment-load {
  color: $color-darker-grey;
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  font-size: 11px;
  font-weight: 500;
}

.multiple-attachment-load {
  color: $color-doc-brand-sec;
}

.multiline-form-box {
  display: inline-block;
  position: relative;

  .caret {
    position: absolute;
    top: 50%;
    right: 10px;
    width: 0;
    height: 0;
    margin-top: -3px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $form-box-text-dark;
  }

  > span {
    z-index: 2;
    position: absolute;
    top: 12px;
    left: 15px;
    font-weight: 400;
    font-size: 0.8em;
    display: block;
    color: $form-box-text;
    pointer-events: none;
  }

  .form-control,
  select {
    display: block;
    min-width: 180px;
    background: $form-box;
    padding: 25px 30px 10px 15px;
    border: none;
    border-bottom: 2px solid $form-box-border;
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: $form-box-text-dark;
    font-size: 1em;
    line-height: 1em;

    &:focus {
      border-bottom: 2px solid $color-doc-brand-sec;
      outline: none;
    }
  }
}
