//Variables
$main-margin: 17.5px;
$border-radius: 4px;
$top-bar-height: 60px;

//Other
.clean-input {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.box-shad-main {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

@mixin create-icon($icon-num, $icon-num-active) {
  font-family: "icomoon";
  &:before {
    content: "#{$icon-num}";
  }
  &.active {
    &:before {
      content: "#{$icon-num-active}";
    }
  }
}

.my-vid-holder {
  width: 15vw;
  height: 15vh;
}
