/** Ultra Light */
@font-face {
  font-family: "San Francisco";
  font-weight: 700;
  src: url("../../assets/fonts/sanfranciscodisplay-bold-webfont.woff2");
}

/** Thin */
@font-face {
  font-family: "San Francisco";
  font-weight: 200;
  src: url("../../assets/fonts/sanfranciscodisplay-thin-webfont.woff2");
}

/** Regular */
@font-face {
  font-family: "San Francisco";
  font-weight: 400;
  src: url("../../assets/fonts/sanfranciscodisplay-regular-webfont.woff2");
}

/** Medium */
@font-face {
  font-family: "San Francisco";
  font-weight: 500;
  src: url("../../assets/fonts/sanfranciscodisplay-medium-webfont.woff2");
}

/** Semi Bold */
@font-face {
  font-family: "San Francisco";
  font-weight: 600;
  src: url("../../assets/fonts/sanfranciscodisplay-semibold-webfont.woff2");
}

/** Bold */
@font-face {
  font-family: "San Francisco";
  font-weight: 700;
  src: url("../../assets/fonts/sanfranciscodisplay-bold-webfont.woff2");
}

$font-light: "San Francisco Display Thin";
$font-normal: "San Francisco Display Medium";
$font-medium: "San Francisco Display Semibold";
$font-bold: "San Francisco Display Bold";

html,
body {
  font-family: "San Francisco", sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: $color-font;
}

h1,
h2,
h3,
h4,
h5,
b,
strong,
.bold,
.strong {
  font-family: "San Francisco", sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 1.875em;
}

h2 {
  font-size: 1.625em;
}

h3 {
  font-size: 1.375em;
}

h4 {
  font-size: 1.125em;
}

a {
  color: $color-doc-brand;
  text-decoration: none;

  &:hover {
    color: darken($color-doc-brand, 5%);
    text-decoration: underline;
  }

  &.link-muted {
    color: $color-dark-grey;

    &:hover {
      color: darken($color-dark-grey, 5%);
    }
  }
}
