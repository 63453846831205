.md-pricing {
  .modal {
    padding: 20px;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: 380px;
    border: none;
    border-radius: 8px;
    background: $color-white;
    color: $color-font-grey;

    .md-header {
      position: relative;
      min-height: 22px;
      padding: 20px 10px 5px;

      .md-close {
        top: 0;
        right: 0;
        color: inherit;
        font-size: 22px;
      }

      .md-back {
        position: absolute;
        top: 2px;
        left: 0;
        color: inherit;
        font-size: 18px;

        &:hover {
          text-decoration: none;
        }
      }

      .md-header-link {
        display: block;
        text-align: center;
        text-transform: uppercase;
        color: lighten($color-doc-brand, 10%);
        font-weight: 500;
        font-size: 12px;
        text-decoration: underline;
      }
      .md-header-h1 {
        display: block;
        margin-top: 15px;
        text-align: center;
        text-transform: uppercase;
        color: $color-doc-brand;
        font-weight: 500;
        font-size: 18px;
      }
      .md-header-h4 {
        display: block;
        margin-top: 15px;
        text-align: center;
        text-transform: uppercase;
        color: $color-doc-brand;
        font-weight: 300;
        font-size: 0.8em;
      }
    }

    .md-body {
      padding-top: 30px;
    }

    .md-footer-message {
      text-align: center;
      font-size: 12px;
      padding-bottom: 10px;

      span {
        display: block;
        font-weight: 500;
        margin-bottom: 5px;
      }

      a {
        font-weight: 500;
        color: $color-doc-brand-sec;

        &:hover {
          color: $color-doc-brand-sec;
          text-decoration: underline;
        }
      }
    }
  }

  &__block {
    text-align: center;
    font-size: 1.1em;
    margin-bottom: 30px;

    span {
      display: flex;
      justify-content: center;
    }

    &__from {
      display: block;
      margin: 0 auto 5px;
      font-size: 0.7em;
      font-weight: 300;
    }

    &__price {
      font-size: 2.2em;
      color: $color-doc-brand-sec;
      font-weight: 400;
      margin-bottom: 5px;

      span {
        display: inline;
        vertical-align: baseline;
        line-height: 1;
      }

      sup {
        vertical-align: super;
        font-size: 50%;
        top: 0;
        line-height: 1;
      }
    }

    &__type {
      margin-bottom: 12px;
      font-weight: 600;

      i {
        color: $color-doc-brand;
        margin-right: 5px;
      }
    }

    &__detail {
      font-weight: 400;
    }

    &__desc {
      font-weight: 300;
      margin: 20px 0;
      padding: 0 60px;
      line-height: 1.32;
    }

    &__desc_bold {
      font-weight: 400;
      margin: 10px 0 30px;
      padding: 0 10px;
      line-height: 1.32;
    }

    &__style {
      position: relative;
      display: flex;
      justify-content: space-between;
      max-width: 180px;
      margin: 10px auto 0;
      padding-top: 30px;

      &:after {
        content: "";
        position: absolute;
        top: 15px;
        left: 18px;
        right: 18px;
        height: 2px;
        background: $color-mid-grey;
      }

      a {
        display: block;
        width: 40px;
        flex-shrink: 0;
        position: relative;
        font-size: 0.65em;
        text-transform: uppercase;
        color: inherit;

        &:before {
          content: "";
          z-index: 2;
          position: absolute;
          top: -20px;
          left: 50%;

          width: 12px;
          height: 12px;
          margin-left: -6px;
          background-color: transparent;
          border-radius: 50%;
        }

        &:after {
          content: "";
          z-index: 3;
          position: absolute;
          top: -17px;
          left: 50%;

          width: 6px;
          height: 6px;
          margin-left: -3px;
          background-color: $color-mid-grey;
          border-radius: 50%;
        }

        &.active {
          color: $color-doc-brand-sec;

          &:before {
            background-color: $color-doc-brand-sec;
          }

          &:after {
            background-color: $color-white;
          }
        }
      }
    }

    &__info {
      padding: 0;
      margin: 50px 0 30px !important;

      li {
        position: relative;
        width: 100%;
        max-width: 220px;
        margin: 0 auto;
        padding-left: 22px;
        list-style: none;

        i {
          position: absolute;
          top: 3px;
          left: 0;
          color: $color-doc-brand-sec;
          font-size: 12px;
        }

        p {
          font-size: 0.85em;
          line-height: 1.2;
          text-align: left;
          font-weight: 400;
        }
      }
    }

    .btn {
      margin: 22px auto 0;
      width: 100%;
      max-width: 220px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 0.9em;
      text-transform: uppercase;

      &.disabled {
        background: $color-dark-grey;
        border-color: $color-dark-grey;
        cursor: not-allowed;
      }
    }
  }

  &_colored {
    .modal {
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#14a5c9+0,382051+100&0.9+1,0.9+100 */
      background: -moz-linear-gradient(
        -45deg,
        rgba(20, 165, 201, 0.9) 0%,
        rgba(20, 164, 200, 0.9) 1%,
        rgba(56, 32, 81, 0.9) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        -45deg,
        rgba(20, 165, 201, 0.9) 0%,
        rgba(20, 164, 200, 0.9) 1%,
        rgba(56, 32, 81, 0.9) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        135deg,
        rgba(20, 165, 201, 0.9) 0%,
        rgba(20, 164, 200, 0.9) 1%,
        rgba(56, 32, 81, 0.9) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e614a5c9', endColorstr='#e6382051',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

      .md-header {
        padding: 6px 0;

        .md-header-h4 {
          margin-top: 0;
          color: $color-white;
        }

        .md-close {
          color: $color-white;
        }
      }

      .md-pricing__block__price,
      .md-pricing__block__from {
        color: $color-white;
      }

      .md-pricing__block__detail {
        color: $color-white;
        font-size: 1.1em;
        font-weight: 300;
      }
    }
  }

  .md-pricing__block {
    margin-bottom: 40px;

    .btn {
      max-width: 100%;
      margin-top: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .md-pricing__block__desc {
    color: $color-white;
    .capitalize {
      text-transform: capitalize;
      display: inline !important;
    }
  }

  .md-pricing__block__desc_bold {
    color: $color-white;
  }
}
