.modal-drop {
  z-index: 99999999;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.payments-modal {
  height: 100vh;
  color: #888888;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  .main-wrap {
    display: inline-block;
    border-radius: 4px;
    width: 300px;
    position: relative;
    background-color: #ffffff;
    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.5),
      inset 0 1px 0 0 hsla(0, 0%, 100%, 0.65);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    .pm-header {
      position: relative;
      padding: 12px 36px;
      text-align: center;
      background-color: #f9f9f9;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      display: block;
      margin-bottom: 0;

      .pm-close,
      .pm-back {
        position: absolute;
        cursor: pointer;
        width: 25px;
        height: 25px;
        background-size: 17px;
        background-position: 4px 4px;
        right: 5px;
        top: 8px;
        color: #888;
        font-size: 18px;

        &:hover {
          text-decoration: none;
        }
      }

      .pm-back {
        right: initial;
        left: 5px;
      }

      h1 {
        font-size: 17px;
        line-height: 1.5;
        font-weight: 700;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .header-div {
        height: 1px;
        background-image: radial-gradient(
          circle,
          hsla(0, 0%, 100%, 0.31),
          hsla(0, 0%, 100%, 0)
        );
        margin-bottom: 6px;

        &:before {
          content: "";
          display: block;
          position: relative;
          height: 1px;
          top: -1px;
          background-image: radial-gradient(
            circle,
            rgba(0, 0, 0, 0.07),
            transparent
          );
        }
      }

      p {
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        color: #919199;
        text-shadow: 0 1px 0 hsla(0, 0%, 100%, 0.4);
        vertical-align: top;
        font-size: 0.875em;
        margin-bottom: 0;
      }
    }

    .pm-body {
      padding: 30px;

      .pmf-group {
        display: flex;
        align-content: center;
        align-content: center;

        .form-styled,
        select {
          flex: 1;
          font-size: 0.875em;
          border: none;
          outline: none;
          height: 40px;
          background: #ffffff;
          border: 1px solid rgba(22, 23, 56, 0.15);
          border-bottom: none;
          border-radius: 0;
          padding: 0 10px;
          -webkit-appearance: none;
          margin-bottom: 0;

          &.has-error {
            box-shadow: inset 0px 0px 2px 0px #fe0000;
          }

          &:first-child {
            border-right: none;
          }

          &:last-child {
            border-right: 1px solid rgba(22, 23, 56, 0.15);
          }

          &:focus {
            z-index: 2;
            border-radius: 4px;
            border: none;
            box-shadow: 0px 0px 2px 4px rgba(62, 169, 230, 0.35);
          }
        }

        &:first-child {
          .form-styled {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }
        }

        &:last-child {
          .form-styled,
          select {
            border-bottom: 1px solid rgba(22, 23, 56, 0.15);

            &:first-child {
              border-bottom-left-radius: 4px;
            }

            &:last-child {
              border-bottom-right-radius: 4px;
            }
          }
        }
      }

      .pmf-group-select {
        position: relative;

        select {
          width: 100%;
        }

        &.has-error {
          select {
            box-shadow: inset 0px 0px 2px 0px #fe0000;
          }
        }

        &:before {
          z-index: 9;
          position: absolute;
          content: "";
          top: 17px;
          right: 15px;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #919199;
        }
      }

      .pmf-amount {
        text-align: center;

        em {
          display: block;
          font-size: 0.85em;
          margin-bottom: 5px;
        }

        b {
          display: block;
          font-size: 2.75em;
        }
      }

      .pmf-button {
        margin-top: 20px;
        display: flex;

        button {
          position: relative;
          flex: 1;
          padding: 0 37px;
          border-radius: 4px;
          background-color: #0084ff;
          font-size: 14px;
          line-height: 18px;
          height: 37px;
          color: #fff;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: #007aeb;
          }

          i {
            position: absolute;
            right: 0;
            top: 0;
            width: 37px;
            height: 37px;
            line-height: 37px;
            font-size: 18px;
          }
        }
      }

      .pmf-loading {
        height: 200px;

        .loader-wrapper {
          top: 10px;
          height: 190px;

          .spinner {
            margin: 50px auto;
          }
        }

        span {
          display: block;
          text-align: center;
          font-size: 0.875em;
        }
      }
    }
  }
}
