//Top Nav

.responsive-menu-icon {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: $top-bar-height;
  height: $top-bar-height;
  text-align: center;
  cursor: pointer;

  i {
    line-height: $top-bar-height;
    font-size: 24px;
    color: #fff;
  }
}

.mobile-notification-action {
  display: none;
  position: absolute;
  top: 0;
  right: -1px;
  width: 60px;
  height: $top-bar-height;
  text-align: center;

  &.mna-bell {
    right: 60px;

    @media only screen and (max-width: 1024px) {
      right: 0;
    }
  }

  a {
    position: relative;
    display: inline-block;
    width: 60px;
    line-height: $top-bar-height;
    color: #fff;
    font-size: 24px;

    &:hover {
      text-decoration: none !important;
    }

    i {
      display: block;
      line-height: $top-bar-height;
    }

    .user-count {
      position: absolute;
      top: 22px;
      right: 0;
      width: 20px;
      height: 20px;
      margin: 0;
      background-color: $color-danger;
      color: $color-white;
      text-align: center;
      line-height: 20px;
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      border-radius: 50%;
    }
  }
}

.top-bar {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: $top-bar-height;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;

  background: url("../../assets/images/call-header-bg.png"), #252665; /* Old browsers */
  background: url("../../assets/images/call-header-bg.png"),
    -moz-linear-gradient(-45deg, #252665 0%, #16a3b3 100%); /* FF3.6-15 */
  background: url("../../assets/images/call-header-bg.png"),
    -webkit-linear-gradient(-45deg, #252665 0%, #16a3b3 100%); /* Chrome10-25,Safari5.1-6 */
  background: url("../../assets/images/call-header-bg.png"),
    linear-gradient(135deg, #252665 0%, #16a3b3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-position: left top;
  background-repeat: no-repeat;

  .main-brand {
    width: 200px;
    height: calc(#{$top-bar-height} - 16px);
    margin: 8px 0;
    background-image: url("../../assets/images/call-header-brand.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    float: left;
    flex-shrink: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .header-title {
    position: absolute;
    width: 100%;
    margin: 0 auto;
    line-height: $top-bar-height;
    text-align: center;
    color: $color-white;
    font-size: 28px;
  }

  .doctor-name {
    color: $color-white;
    font-size: 20px;
    float: left;
    line-height: c;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    margin-left: 20px;
    padding-left: 25px;
  }

  .nav {
    float: right;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    > li {
      cursor: pointer;
      position: relative;
      float: left;
      min-width: 60px;
      text-align: center;
      padding: 0;
      height: $top-bar-height;

      > a,
      > span {
        position: relative;
        display: block;
        min-width: 60px;
        padding: 0 $main-margin;
        color: $color-white;
        text-decoration: none;
        line-height: 1.2;
        font-size: 1.1em;
        text-transform: uppercase;
        height: $top-bar-height;
        display: flex;
        align-items: center;
        align-content: center;

        &:hover {
          background-color: rgba($color-white, 0.1);
        }
        span,
        i {
          vertical-align: middle;
        }
        span {
          display: inline-block;
        }
        i {
          font-size: 22px;
          vertical-align: middle;
        }
        .user-count {
          position: absolute;
          top: 16px;
          right: 4px;
          width: 20px;
          height: 20px;
          margin: 6px 6px 0 0;
          background-color: $color-danger;

          color: $color-white;
          text-align: center;
          line-height: 20px;
          font-style: normal;
          font-size: 14px;
          font-weight: 500;
          @include border-top-radius(50%);
          @include border-bottom-radius(50%);
        }

        &.end-call {
          background: $color-danger;
          border: none;
          padding: 0 25px;
          &:hover {
            background: lighten($color-danger, 5%);
          }
          i {
            margin-left: 5px;
          }
        }
      }

      &:hover {
        background: rgba(255, 255, 255, 0);

        .drop-menu {
          display: block;
        }
      }

      &.logout-link {
        a {
          text-transform: none;

          span {
            margin: 0;
            display: inline-block;
          }

          .user-name {
            text-transform: capitalize;
          }

          .divider {
            margin: 0 4px;
          }
        }
      }

      &.settings-link {
        margin-left: 35px;
      }
    }
  }

  .nav-message {
    .logout-link {
      margin-right: -124px;
    }
    .border {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
    .bottom {
      margin-top: $top-bar-height;
      background-color: $color-top-bar;
      height: 40px;
      float: right;

      a {
        line-height: 40px;
      }
    }
  }
}

.drop-menu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0;
  width: auto;
  min-width: 240px;
  white-space: nowrap;
  background: $color-white;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.2);
  @include border-bottom-radius($border-radius);

  > li {
    display: block;
    list-style: none;
    text-align: left;

    > a,
    > span {
      position: relative;
      display: block;
      line-height: 45px;
      padding: 0 10px;
      margin: 0;
      color: $color-font;
      background: $color-white;
      cursor: pointer;
      border-bottom: 1px solid $color-border-grey;

      &:hover {
        background: $color-superlight-grey;
        color: $color-doc-brand-sec;
        text-decoration: none;
      }
    }

    &:last-child {
      > a,
      > span {
        @include border-bottom-radius($border-radius);
        border: none;
      }
    }
  }
}
